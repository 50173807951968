import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import "../../../../src/index.scss";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DimmingDots from "./Views/LoaderCustom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InfoIcon from "@mui/icons-material/Info";
import BalanceIcon from "@mui/icons-material/Balance";
import { MdGroups } from "react-icons/md";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { Modal } from "react-bootstrap";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Drawer,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Slide,
  Tooltip,
  Typography,
  useMediaQuery,
  Badge,
  Switch,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
// import { getBookingData } from "./api/ReservationApi";
import {
  channelLogoMap,
  dateFormat,
  getSlotType,
  multiOptions,
  navigationParamsForCalendar,
  views,
} from "../CalendarPage/constants/Calendar-constants";
import { NewBookingDataModel } from "../CalendarPage/models/NewBookingDataModel";
import moment from "moment";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  FiberManualRecord,
  Lock,
  MoreVert,
} from "@mui/icons-material";
import { isNull, isUndefined } from "lodash";
import { getAllNonHiddenRoomTypes } from "../InventoryCalendar/api/InventoryApi";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "../../../index.scss";
import "@fullcalendar/resource-timeline/main.min.css";
import * as AiIcons from "react-icons/ai";
import { AMPLITUDE_KEY, ENV } from "./constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import { getAllRoomTypess } from "../RevenueManagement/RatePlans/apis/RatePlanApis";
import { Dropdown } from "react-bootstrap";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import GroupEditModalView from "./GroupEditModalView";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import GroupExtendModalView from "./models/GroupExtendModalView";
import GroupDragModalView from "./models/GroupDragModalView";
import { connect } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { shallowEqual, useSelector } from "react-redux";
import ModalView from "./Views/ModalView";
import { useTranslation } from "react-i18next";
import i18n from "../../modules/Auth/pages/i18n";
import BookingModal from "./Views/BookingModal";
import { getReservationInfoDjango } from "./api/ReservationApi";
import axios from "axios";
import { getAllImagesAndUrl } from "../BookingDashboard/apis/BrandingModalApi";
import DragModalView from "./Views/DragModalView";
import ExtendModalView from "./Views/ExtendModalView";
import EditModalView from "./Views/EditModalView";
import UnblockView from "./Views/UnblockView";
import { useHistory } from "react-router-dom";
import UnassignedView from "./Views/UnassignedView";
import MultipleBookingView from "./Views/MultipleBookingView";
import NewEnquiry from "./Views/NewEnquiry";
import Walkin from "./Views/Walkin";
import BlockRoom from "./Views/BlockRoom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: "90%",
  },
  drawers: {
    flexShrink: 0,
    width: "55%",
  },
  drawerPaper: {
    width: "90%",
  },
  drawerPapers: {
    width: "55%",
  },
}));

const GroupTimeline = ({ hotelType, data, selectedLanguage }) => {
  const history = useHistory();

  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [allHotelIds, setAllHotelIds] = useState(null);
  function handleSetAllHotelIds() {
    const res = Array.from(new Set(data.hotels.map((item) => item.hotelId)));
    setAllHotelIds(res);
  }
  useEffect(() => {
    if (data) {
      handleSetAllHotelIds();
    }
  }, [data]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const hotelId = hotel.hotelId;

  const [groupData, setgroupData] = useState([]);
  const [invtenoryData, setinvtenoryData] = useState([]);

  const [roomInvData, setroomInvData] = useState(new Map());

  const addToMap = (key, value) => {
    console.log("key", key, value);
    setroomInvData((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(key, value);
      return newMap;
    });
  };

  const getAllDatas = () => {
    setLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/navigationRoomType`,
      {
        roomIdsSort: true,
        hotelId: hotelId,
        startDate: moment(new Date()).format("DD-MM-YYYY HH:00:00"),
        numOfDays: 7,
        roomTypes: null,
        availableRooms: false,
        blockedRooms: false,
        dirtyRooms: false,
        cleanRooms: false,
        viewType: "resourceTimelineWeek",
        bookedRooms: false,
        clusterRooms: false,
      }
    )
      .then((res) => {
        setgroupData(res.allRoomReservations);
        setinvtenoryData(res.invSummaryList);
        const opo = res?.allRoomReservations?.map((item) => {
          if (
            item.roomTypeId !== "" &&
            item.roomTypeId !== undefined &&
            item.roomTypeId !== null
          ) {
            addToMap(item.roomTypeId, item.invSummaryList);
          }
        });

        setLoading(false);
      })
      .catch((err) => {
        console.error("Error in fecthing data");
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllDatas();
  }, []);

  //   const hotelSlot = getSlotType(hotel.slotType);
  const Noonslot = { day: 0, hour: 8 };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [dashboardView, setDashboardView] = useState(
    sessionStorage.getItem("fullView") === "false" ? "roomView" : "fullView"
  );

  useEffect(() => {
    if (hotelId !== "" && hotelId !== null && hotelId !== undefined) {
      if (
        sessionStorage.getItem("fullView") === "false" ||
        sessionStorage.getItem("fullView") === false
      ) {
        history.push(`/roomcalendar?hotelId=${hotelId}`);
      } else {
        history.push(`/calendar?hotelId=${hotelId}`);
      }
    }
  }, [sessionStorage.getItem("fullView"), dashboardView, hotelId]);
  //   const hotelId = hotel.hotelId;
  const [calendarRefArray, setCalendarRefArray] = useState([]);
  const calendarRef = useRef(null);
  let calRefArray = useRef([]);
  const navigationParams = Object.assign(navigationParamsForCalendar);
  let [actionType, setActionType] = useState("");
  let [showModal, setShowModal] = useState({});
  let [show, setShow] = useState(false);
  let [initialData, setInitialData] = useState(new NewBookingDataModel({}));
  const [calendarApiArray, setcalendarApiArray] = useState([]);
  let [calendarApi, setCalendarApi] = useState(null);
  let [loading, setLoading] = useState(false);
  // let [snackbar, setSnackbar] = useState(false);
  const [rooms, setRooms] = useState([
    { text: "Available Rooms", id: "availableRooms" },
    { text: "Blocked Rooms", id: "blockedRooms" },
    { text: "Dirty Rooms", id: "dirtyRooms" },
    { text: "Clean Rooms", id: "cleanRooms" },
    { text: "Occupied Rooms", id: "bookedRooms" },
    { text: "All", id: "All" },
    { text: "Unselect", id: "Unselect" },
  ]);

  const [selectedRoom, setSelectedRoom] = useState([]);
  const [today, setToday] = useState(new Date());
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [multiOption, setMultiOption] = useState("");
  const onOpenEditCustomerModal = () => setOpenEditCustomerModal(true);
  const onCloseEditCustomerModal = () => setOpenEditCustomerModal(false);
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const onOpenServiceModal = () => setOpenServiceModal(true);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const windowWidth = window.screen.innerWidth;
  const [state, setState] = useState({ right: false });
  const [showUnassigned, setShowUnassigned] = useState(false);
  const [unassignedDate, setUnassignedDate] = useState(new Date());
  const [originalAllRoomInfo, setOriginalAllRoomInfo] = useState([]);
  const [showRoomName, setshowRoomName] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dirtyRoomId, setdirtyRoomId] = useState("");
  const [initailDataArray, setinitailDataArray] = useState([]);
  const [roomNumberArray, setroomNumberArray] = useState([]);
  const [claendarViewType, setclaendarViewType] = useState(
    "resourceTimelineWeek"
  );

  const [roomInfo, setroomInfo] = useState(null);
  const [hotelSlotType, sethotelSlotType] = useState("");
  const [hotelCurrency, sethotelCurrency] = useState("INR");
  const [hotelCountry, sethotelCountry] = useState("India");
  const [bookingData, setbookingData] = useState({});
  const [openBookingModal, setopenBookingModal] = useState(false);
  const [reservationData, setreservationData] = useState({});
  const currency = hotel.accessControl.hotel.hotel_currency;
  const loggedUserEmail = localStorage.getItem("email");
  const envType = process.env.REACT_APP_ENV;
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const [hotelemail, sethotelemail] = useState(
    hotel?.accessControl?.hotel?.hotel_email
  );

  // cash payment loading
  const [cashPayLoading, setCashPayLoading] = useState(false);
  const [amount, setAmount] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  // cheque pay loading
  const [offlineCheckPayLoading, setOfflineCheckPayLoading] = useState(false);
  const [offlineCardAmt, setOfflineCardAmt] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  const [offlineCheckDate, setOfflineCheckDate] = useState(new Date());
  const [offlineCashDate, setOfflineCashDate] = useState(new Date());
  const [chequeDesc, setChequeDesc] = useState();
  const [serviceAmountDesc, setServiceAmountDesc] = useState();
  const [offlineCardDesc, setOfflineCardDesc] = useState();
  const handleCloseCashPayment = () => setOpenCashPayment(false);
  const handleCloseOfflineCardPay = () => {
    setOpenOfflineCardPay(false);
  };
  const [openMagicLinkModal, setOpenMagicLinkModal] = useState(false);
  const handleOpenMagicLinkModal = () => {
    setOpenMagicLinkModal(true);
  };

  useEffect(async () => {
    if (Object.entries(bookingData).length > 0) {
      const data = await getReservationInfoDjango(bookingData.reservationId);
      setreservationData(data);
      setMagicLinkMail(data.user_email);
      setMagicLinkPhone(data.user_contact);
      setUpiAmt(data.balance_due);
      setAmount(data.balance_due);
      setOfflineCardAmt(data.balance_due);
      setBankTransferModeAmt(data.balance_due);
      setOtherModeAmt(data.balance_due);
      setOfflineCheckAmt(data.balance_due);
      setPaymentLinkAmt(data.balance_due);
    }
  }, [bookingData]);

  const handleCloseMagicLinkModal = () => {
    setOpenMagicLinkModal(false);
  };
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const whitlableArrMap = [
    "STAYFLEXI_OD",
    "STAYFLEXI_OM",
    "STAYFLEXI MOBILE",
    "STAYFLEXI_GHA",
    "STAYFLEXI_BE",
    "STAYFLEXI_MOBILE",
  ];

  // handling booking confirmation email loading
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // pre populating magic link modal fields
  const [magicLinkMail, setMagicLinkMail] = useState("");
  const [magicLinkPhone, setMagicLinkPhone] = useState("");
  // send magic link
  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  function handleSendMagicLink() {
    if (!validateEmail(magicLinkMail)) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail.includes("example.com")) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail == "" || magicLinkPhone == "") {
      toast.error(
        `${t("Empty email or phone, please enter valid email and phone.")}`
      );
      setMagicLinkLoading(false);
      return;
    }
    setMagicLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendSelfServiceLinkCust/",
      {
        bookingId: bookingData.booking_id,
        email: magicLinkMail,
        phoneNumber: magicLinkPhone,
      }
    )
      .then((res) => toast.success(`${t("Magic link sent successfully.")}`))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseMagicLinkModal())
      .then((res) => setMagicLinkMail(reservationData.user_email))
      .then((res) => setMagicLinkPhone(reservationData.user_contact))
      .catch((err) => toast.error(err))
      .catch((err) => setMagicLinkLoading(false));
  }

  // UPI payment modal loading
  const [openUpi, setOpenUpi] = useState(false);
  const handleOpenUpiPay = () => setOpenUpi(true);
  const handleCloseUpiPay = () => setOpenUpi(false);
  const [upiPayLoading, setUpiPayLoading] = useState(false);

  const [upiDate, setUpiDate] = useState(new Date());

  const [upiAmt, setUpiAmt] = useState(reservationData?.balance_due);
  const [upiDesc, setUpiDesc] = useState("");

  const handleUpiPayment = () => {
    setUpiPayLoading(true);
    axios
      .post(`${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`, {
        hotelId: hotel?.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: "",
        amount: Number(upiAmt),
        offline_upi_date: handleSentDateFormat(upiDate),
        service_amount_desc: upiDesc,
        logged_user_email: loggedUserEmail,
      })
      // .then((res) => updateSummaryDataFromCache())
      .then((res) =>
        toast.success(
          `${t("UPI payment of")} ${upiAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setUpiPayLoading(false))
      .then((res) => setUpiAmt(""))
      .then((res) => setUpiDate(new Date()))
      .then((res) => setUpiDesc(""))
      .then((res) => handleCloseUpiPay())
      .catch((err) => toast.error(err))
      .then((err) => setUpiPayLoading(false))
      .then((err) => handleCloseUpiPay());
  };

  const [manualResCard, setManualResCard] = useState(false);
  const handleChangeManualResCard = (e) => {
    setManualResCard(e.target.checked);
  };

  // handling the format in which data is being sent
  const handleSentDateFormat = (recordDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(recordDate).format("YYYY-MM-DD")
    ) {
      return moment(recordDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(recordDate).format("YYYY-MM-DD") + " 00:00:00";
  };

  // Other payment mode modal loading
  const [openOtherModePay, setOpenOtherModePay] = useState(false);
  const handleOpenOtherModePay = () => setOpenOtherModePay(true);
  const handleCloseOtherModePay = () => setOpenOtherModePay(false);
  const [otherModePayLoading, setOtherModePayLoading] = useState(false);

  // Bank transfer payment mode modal
  const [openBankTransferModePay, setOpenBankTransferModePay] = useState(false);
  const handleOpenBankTransferModePay = () => setOpenBankTransferModePay(true);
  const handleCloseBankTransferModePay = () =>
    setOpenBankTransferModePay(false);

  const [bankTransferModePayLoading, setBankTransferModePayLoading] =
    useState(false);

  const [bankTransferModeDate, setBankTransferModeDate] = useState(new Date());

  const [bankTransferModeAmt, setBankTransferModeAmt] = useState(
    reservationData?.balance_due
  );
  const [bankTransferModeDesc, setBankTransferModeDesc] = useState("");

  const [otherModeDate, setOtherModeDate] = useState(new Date());

  const [otherModeAmt, setOtherModeAmt] = useState(
    reservationData?.balance_due
  );
  const [otherModeDesc, setOtherModeDesc] = useState("");

  const handleBankTransferPayment = () => {
    setBankTransferModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordBankTransferPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: bankTransferModeAmt,
          bank_transfer_date: handleSentDateFormat(bankTransferModeDate),
          service_amount_desc: bankTransferModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Bank transfer payment of")} ${bankTransferModeAmt} ${t(
            "recorded for"
          )} ${reservationData?.booking_id}!`
        )
      )
      .catch((err) => toast.error(err))
      .finally(() => setBankTransferModeAmt(""))
      .finally(() => setBankTransferModeDate(new Date()))
      .finally(() => setBankTransferModeDesc(""))
      .finally(() => handleCloseBankTransferModePay())
      .finally(() => setBankTransferModePayLoading(false));
  };

  const handleOtherModePayment = () => {
    setOtherModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: otherModeAmt,
          other_payment_date: handleSentDateFormat(otherModeDate),
          service_amount_desc: otherModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Other payment of")} ${otherModeAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setOtherModePayLoading(false))
      .then((res) => setOtherModeAmt(""))
      .then((res) => setOtherModeDate(new Date()))
      .then((res) => setOtherModeDesc(""))
      .then((res) => handleCloseOtherModePay())
      .catch((err) => setOtherModePayLoading(false))
      .catch((err) => handleCloseOtherModePay())
      .catch((err) => toast.error(err));
  };

  // paymentLink modal

  const [openSendPaymentLink, setOpenSendPaymentLink] = useState(false);
  const handleOpenSendPaymentLink = () => setOpenSendPaymentLink(true);
  const handleCloseSendPaymentLink = () => setOpenSendPaymentLink(false);

  // cash deposit modal

  const [openCashDeposit, setOpenCashDeposit] = useState(false);
  const handleOpenCashDeposit = () => setOpenCashDeposit(true);
  const handleCloseCashDeposit = () => setOpenCashDeposit(false);
  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    reservationData?.balance_due
  );

  // handling cash deposit date format
  const handleOpenCashDepositDate = (cashDepositDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(cashDepositDate).format("YYYY-MM-DD")
    ) {
      return moment(cashDepositDate).format("MMM DD YYYY HH:mm:ss");
    }
    return moment(cashDepositDate).format("MMM DD YYYY") + " 00:00:00";
  };
  // deposit loading
  const [depositLoading, setDepositLoading] = useState(false);
  // handling the cash deposit

  const handleDeposit = () => {
    setDepositLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/addServiceToInvoice/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          service_item: {
            service_date: handleOpenCashDepositDate(depositDate),
            service_amount: depositAmt,
            service_desc: depositName,
            service_amount_type: "DEPOSIT",
          },
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => toast.success(`${t("Deposit recorded successfully.")}`))

      .then((res) => setDepositLoading(false))
      .then((res) => setDepositAmt(""))
      .then((res) => setDepositDate(new Date()))
      .then((res) => setDepositName())
      .then((res) => handleCloseCashDeposit())
      .catch((error) => setDepositLoading(false))
      .catch((error) => handleCloseCashDeposit())
      .catch((error) => toast.error(error));
  };

  // fields for for cash deposit

  const [depositAmt, setDepositAmt] = useState(0);
  const [depositDate, setDepositDate] = useState(new Date());
  const [depositName, setDepositName] = useState();
  const [offlineCardDate, setOfflineCardDate] = useState(new Date());
  const handleCloseOfflineCheckPay = () => setOpenOfflineCheckPay(false);

  const [openOfflineCardPay, setOpenOfflineCardPay] = useState(false);
  const handleOpenOfflineCardPay = () => {
    if (hotelType === "ISHA") {
      toast.error(
        `${t(
          "Please ensure to preview the E-Receipt before accepting the payment"
        )}`
      );
    }
    setOpenOfflineCardPay(true);
  };

  const handleOfflineCardPayment = () => {
    const payload = {
      hotelId: hotel?.hotelId,
      bookingID: reservationData.booking_id,
      reservation_id: "",
      amount: offlineCardAmt,
      offline_card_date: handleSentDateFormat(offlineCardDate),
      service_amount_desc: offlineCardDesc,
      logged_user_email: loggedUserEmail,
    };

    setOfflineCardPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCardPayment/`,
        payload
      )
      .then((res) =>
        toast.success(
          `${t("Card payment of")} ${offlineCardAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setOfflineCardPayLoading(false))
      .then((res) => setOfflineCardAmt(""))
      .then((res) => setOfflineCardDate(new Date()))
      .then((res) => setOfflineCardDesc())
      .then((res) => handleCloseOfflineCardPay())
      .catch((err) => setOfflineCardPayLoading(false))
      .catch((err) => handleCloseOfflineCardPay())
      .catch((err) => toast.error(err));
  };

  // handling the offline cheque payment flows

  const handleOfflineCheckPayment = () => {
    setOfflineCheckPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCheckPayment/`,
        {
          hotelId: hotel?.hotelId,
          booking_id: reservationData?.booking_id,
          reservation_id: "",
          amount: offlineCheckAmt,
          offline_card_date: handleSentDateFormat(offlineCardDate),
          logged_user_email: loggedUserEmail,
          service_amount_desc: chequeDesc,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Offline cheque payment of")} ${offlineCheckAmt} ${t(
            "recorded for"
          )} ${reservationData?.booking_id}!`
        )
      )

      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc())
      .then((res) => handleCloseOfflineCheckPay())
      .catch((error) => toast.error(error))
      .then((res) => setOfflineCheckPayLoading(false))
      .then((res) => handleCloseOfflineCheckPay())
      .then((res) => setOfflineCheckAmt(""))
      .then((res) => setOfflineCheckDate(new Date()))
      .then((res) => setChequeDesc());
  };

  // paymentLink loading
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [paymentLinkEmail, setPaymentLinkEmail] = useState(
    reservationData?.user_email
  );
  const [paymentLinkMobile, setPaymentLinkMobile] = useState(
    reservationData?.user_contact
  );
  const [paymentLinkAmt, setPaymentLinkAmt] = useState(
    reservationData?.balance_due
  );
  const [openOfflineCheckPay, setOpenOfflineCheckPay] = useState(false);
  const handleOpenOfflineCheckPay = () => setOpenOfflineCheckPay(true);

  // sending customer payment link
  function handlePaymentLink() {
    setPaymentLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendInvoicePaymentCustEmail/",
      {
        hotel_id: hotel?.hotelId,
        booking_id: reservationData?.booking_id,
        reservation_id: null,
        email: paymentLinkEmail,
        phone: paymentLinkMobile,
        amount: paymentLinkAmt,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) =>
        toast.success(`${t("Payment link sent to the customer successfully.")}`)
      )

      .then((res) => setPaymentLinkLoading(false))
      .then((res) => handleCloseSendPaymentLink())
      .catch((err) => setPaymentLinkLoading(false))
      .catch((err) => toast.error(err));
  }

  const [defaultCtadropdown, setDefaultdropdown] = useState([]);
  // handling the CTA dropdowns
  const [ctaDropdown, setCtaDropdown] = useState([]);
  function handleCtaDropdown() {
    // handleTrueWrapperMockLoader();
    setCtaDropdown([]);
    setDefaultdropdown([]);
    // if (Number(summaryData?.total_cta_balance) === 0) {
    // setCtaDropdown([
    //   {
    //     ctaName: "Walk-In",
    //     taxId: null,
    //   },
    // ]);
    djangoHandleDataRequests("invoice/getBookingSrcDropdownData/")
      .then((res) => {
        setDefaultdropdown(res);
        Number(summaryData?.total_cta_balance) === 0 && setCtaDropdown(res);
      })

      .catch((err) => toast.error(`${t("Error fethcing cta dropdown.")}`));
  }

  // Payment Flows
  const [offlineCardPayLoading, setOfflineCardPayLoading] = useState(false);

  // accepting cash payment modal
  const [openCashPayment, setOpenCashPayment] = useState(false);
  const handleOpenCashPayment = () => {
    if (
      hotelType === "ISHA" &&
      Number(reservationData.total_amount_with_services).toFixed(2) > 100000
    ) {
      toast.error(
        `${t("Cannot take cash transaction of more than 100000 in cash")}`
      );
    } else {
      if (hotelType === "ISHA") {
        toast.error(
          `${t(
            "Please ensure to preview the E-Receipt before accepting the payment"
          )}`
        );
      }
      setOpenCashPayment(true);
    }
  };

  useEffect(() => {
    if (
      bookingData.bookingId !== null &&
      bookingData.bookingId !== undefined &&
      bookingData.bookingId !== ""
    ) {
      handleGetSummaryDataWithCache();
      handleCtaDropdown();
      getData();
      getCustomFolioConfig();
      getCompanyDetails();
    }
  }, [bookingData.bookingId]);

  const [invoiceData, setInvoiceData] = useState(null);

  // Get custom folio config
  const [customFolioConfig, setCustomFolioConfig] = useState(null);
  const getCustomFolioConfig = () => {
    djangoHandleDataRequests("invoice/getCustomFolioConfig/")
      .then((res) => setCustomFolioConfig(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Error getting the folio config data")}`)
      );
  };

  const [allCompanyDetails, setallCompanyDetails] = useState([]);

  const getCompanyDetails = () => {
    handleSpringDataRequest(`core/api/v1/guest-company-details/get-all`)
      .then((res) => setallCompanyDetails(res))
      .catch((err) => console.error(err));
  };

  // Getting the invoice data for the first time ---  from the cache
  const getData = () => {
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-data-render/?bookingId=${bookingData.bookingId}&fromDb=false`
    )
      .then((res) => {
        setInvoiceData(res);
      })
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(`${t("Error fetching the details")}`));
  };

  // Get the data for invoice summary
  const [summaryData, setSummaryData] = useState(null);
  const handleGetSummaryDataWithCache = () => {
    setSummaryData(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-summary-data/?bookingId=${bookingData.bookingId}&fromDB=false`
    )
      .then((res) => setSummaryData(res))

      // .then((res) => fetchBookingData())
      .catch((err) =>
        toast.error(`${t("Failed in getting the payment summary")}`)
      );
  };

  // Getting the hotel logo
  const [hotelLogo, sethotelLogo] = useState(null);
  const handleBase64Img = () => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => sethotelLogo(res))
      .catch((err) => toast.error(`${t("Error in fethcing the hotel logo!")}`));
  };

  // Getting the hotel logo
  useEffect(() => {
    summaryData && invoiceData && hotelLogo === null && handleBase64Img();
  }, [summaryData, invoiceData, hotelLogo, hotelId]);

  const openTheBookingModal = (resp) => {
    setbookingData(resp);
    setopenBookingModal(true);
  };
  const closeTheBookingModal = () => setopenBookingModal(false);

  //Amplitude check on create booking page
  const amplitudeEventProperties = {
    hotel_id: hotel?.hotelId,
    environment: ENV,
    email: hotel?.accessControl?.email,
    role: hotel?.accessControl?.ADMINRIGHTS,
  };

  /* useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_KEY, hotel?.hotelId, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", hotel?.hotelId)
          .set("email", hotel?.accessControl?.email)
          .set("role", hotel?.accessControl?.ADMINRIGHTS)
      );
    amplitude
      .getInstance()
      .logEvent("CREATE BOOKING PAGE LOADED", amplitudeEventProperties);
  }, []); */

  const classes = useStyles();
  const changeDateTime = (data) => {
    showModal.event.setDates(data.start, data.end);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const moveToNewRoom = (id) => {
    showModal.event.setResources([id]);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const getStartEndDates = () => {
    let title = calendarApiArray[0].view.type;
    let currentDate = calendarApiArray[0].getDate();
    let endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + (views[title] - 1));
    return { start: currentDate, end: endDate };
  };

  const lockEvent = (lockType) => {
    showModal.event.setExtendedProp("locked_status", lockType);
    handleAddEventSidebar();
  };

  const removeEvent = () => {
    showModal.event.remove();
    if (calendarApiArray[0] !== null) {
      calendarApiArray[0].refetchEvents();
    }
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  // Creating a new event when selecting in a empty space

  const handleDateSelect = (
    data,
    bookings,
    hotelId,
    slot,
    country,
    currency
  ) => {
    if (data.event.extendedProps.status === "COMBO") return;
    if (data.event.extendedProps.status === "BLOCKED")
      setActionType("edit-blocked");
    else setActionType("edit");
    setroomInfo(bookings);
    sethotelCurrency(currency);
    sethotelCountry(country);
    sethotelSlotType(slot);
    setShowModal(data);
    setShow(true);
  };

  // When event position changes for Drag and drop and extension
  const handleDragStop = (data) => {
    if (
      data.event.getResources()[0].id !== data.oldEvent.getResources()[0].id &&
      data.oldEvent.startStr !== data.event.startStr &&
      data.oldEvent.endStr !== data.event.endStr
    ) {
      data.revert();
      return;
    }
    setShowModal(data);
    setShow(true);
  };

  // Closing the modal for creating new event
  const handleAddEventSidebar = () => {
    setShow(!show);
    setShowModal(null);
  };

  // Reverting the changes when drag and drop is cancelled
  const modify = () => {
    showModal.revert();
    handleAddEventSidebar();
  };

  // Opening a modal for new event in the empty boxes

  const addNew = (data, bookings, hotelId, slot, country, currency) => {
    setroomInfo(bookings);
    sethotelCurrency(currency);
    sethotelCountry(country);
    sethotelSlotType(slot);
    setActionType("add");
    setShowModal(data);
    setShow(true);
  };

  // Adding the new event to the calendar
  const setNewData = (obj, val) => {
    if (!val) {
      calendarApiArray[0].addEvent(obj);
      if (calendarApiArray[0] !== null) {
        calendarApiArray[0].refetchEvents();
      }
    }

    handleAddEventSidebar();
  };

  const checkForEmptyCalendar = () => {
    if (isNull(calendarApiArray)) {
      return false;
    }
    if (isUndefined(calendarApiArray[0]?.view)) {
      return false;
    }

    return true;
  };

  const fetchBookingDataWithoutLoad = async () => {
    const params = getParamsForView("any", calendarApiArray);
    initialData.roomsData = [];
    initialData.bookingData = [];

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }
    if (params?.roomTypes !== undefined && params?.roomTypes !== null) {
      params.roomTypes = params?.roomTypes.filter(
        (item) =>
          item !== "blockedRooms" &&
          item !== "availableRooms" &&
          item !== "dirtyRooms" &&
          item !== "cleanRooms" &&
          item !== "bookedRooms"
      );
    } else {
      params.roomTypes = null;
    }

    getUnassignedCount(params);

    // const data = await getBookingData(params);
    // const resp = new BookingDataModel(data, params.viewType, "THREE_SLOT");

    const roomsAray = [];
    const InitialArray = [];
    let refArray = [];
    setLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/navigationRoomType`,
      {
        roomIdsSort: true,
        hotelId: hotelId,
        startDate: moment(new Date()).format("DD-MM-YYYY HH:00:00"),
        numOfDays:
          sessionStorage.getItem("types") === "month"
            ? 30
            : sessionStorage.getItem("types") === "day"
            ? 1
            : 7,
        roomTypes: null,
        availableRooms: false,
        blockedRooms: false,
        dirtyRooms: false,
        cleanRooms: false,
        viewType: "resourceTimelineWeek",
        bookedRooms: false,
        clusterRooms: false,
      }
    )
      .then((res) => {
        setinvtenoryData(res.invSummaryList);
        const opo = res.allRoomReservations.map((item) => {
          if (
            item.roomTypeId !== "" &&
            item.roomTypeId !== undefined &&
            item.roomTypeId !== null
          ) {
            addToMap(item.roomTypeId, item.invSummaryList);
          }
        });

        const grouping = res.allRoomReservations?.map((itemimg) => {
          let resp = new NewBookingDataModel(
            itemimg,
            params.viewType,
            hotel.slotType === "NOON" ? "THREE_SLOT" : hotel.slotType
          );
          roomsAray.push(resp.roomsData);
          InitialArray.push(resp);
        });
        // calendarApi.removeAllEvents();

        setinitailDataArray(InitialArray);
        setroomNumberArray(roomsAray);
        // setCalendarRefArray(refArray);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error in fecthing data");
        setLoading(false);
      });
  };

  //Fix
  const [unassingedCount, setunassingedCount] = useState(0);

  const getUnassignedCount = (params) => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/getUnassignCount`,
      {
        hotelId: hotelId,
        numOfDays:
          sessionStorage.getItem("types") === "month"
            ? 30
            : sessionStorage.getItem("types") === "day"
            ? 1
            : 7,
        roomIdsSort: true,
        roomTypes: params === undefined ? null : params.roomTypes,
        startDate:
          params === undefined
            ? hotel?.hotelCurrency === "USD"
              ? moment(new Date(new Date().getTime() - 86400000))
                  .set({ hour: 0, minute: 0, second: 0 })
                  .format(dateFormat)
              : moment(new Date())
                  .set({ hour: 0, minute: 0, second: 0 })
                  .format(dateFormat)
            : params.startDate,
        viewType:
          params === undefined ? "resourceTimelineWeek" : params?.viewType,
      }
    )
      .then((res) => setunassingedCount(Number(res)))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUnassignedCount();
  }, [sessionStorage.getItem("types")]);

  const reloadData = () => {
    let tempData = getParamsForView(
      sessionStorage.getItem("types"),
      calendarApiArray
    );
    fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
  };

  const fetchBookingDataWithParams = async (params, type) => {
    setLoading(true);

    const resps = calendarApiArray.map((item) => {
      item.removeAllEvents();
    });
    // calendarApi.removeAllEvents();
    initialData.roomsData = [];
    initialData.bookingData = [];

    getUnassignedCount(params);

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }

    if (params?.roomTypes !== null && params?.roomTypes !== undefined) {
      params.roomTypes =
        params?.roomTypes.length > 0 &&
        params?.roomTypes.filter(
          (item) =>
            item !== "blockedRooms" &&
            item !== "availableRooms" &&
            item !== "dirtyRooms" &&
            item !== "cleanRooms" &&
            item !== "bookedRooms"
        );
    } else {
      params.roomTypes = null;
    }
    if (
      params?.roomTypes !== null &&
      params?.roomTypes !== undefined &&
      params?.roomTypes === false
    ) {
      params.roomTypes = null;
    }

    // const data = await getBookingData(params);
    let resp;
    const roomsAray = [];
    const InitialArray = [];
    let refArray = [];
    setLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/navigationRoomType`,
      {
        roomIdsSort: true,
        hotelId: hotelId,
        startDate: params.startDate,
        numOfDays:
          sessionStorage.getItem("types") === "month"
            ? 30
            : sessionStorage.getItem("types") === "day"
            ? 1
            : 7,
        roomTypes: null,
        availableRooms: false,
        blockedRooms: false,
        dirtyRooms: false,
        cleanRooms: false,
        viewType: "resourceTimelineWeek",
        bookedRooms: false,
        clusterRooms: false,
      }
    )
      .then((res) => {
        setinvtenoryData(res.invSummaryList);
        const opo = res.allRoomReservations.map((item) => {
          if (
            item.roomTypeId !== "" &&
            item.roomTypeId !== undefined &&
            item.roomTypeId !== null
          ) {
            addToMap(item.roomTypeId, item.invSummaryList);
          }
        });
        if (type === "month" || sessionStorage.getItem("types") === "month") {
          //   resp = new BookingDataModel(data, params.viewType, hotel.slotType);

          const grouping = res.allRoomReservations?.map((itemimg) => {
            let resp = new NewBookingDataModel(
              itemimg,
              params.viewType,
              hotel.slotType === "NOON" ? "THREE_SLOT" : hotel.slotType
            );

            roomsAray.push(resp.roomsData);
            InitialArray.push(resp);
          });
        } else {
          //   resp = new BookingDataModel(data, params.viewType, "THREE_SLOT");

          const grouping = res?.allRoomReservations?.map((itemimg) => {
            let resp = new NewBookingDataModel(
              itemimg,
              params.viewType,
              hotel.slotType === "NOON" ? "THREE_SLOT" : hotel.slotType
            );

            roomsAray.push(resp.roomsData);
            InitialArray.push(resp);
            // refArray.push(calendarRef);
          });
        }

        // setRoomNumbers(resp.roomsData);

        // setInitialData(resp);
        setinitailDataArray(InitialArray);
        setroomNumberArray(roomsAray);
        // setCalendarRefArray(refArray);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error in fecthing data");
        setLoading(false);
      });
  };

  const fetchBookingData = async () => {
    try {
      setLoading(true);
      let temp = Object.assign(navigationParams);
      temp.startDate =
        hotel?.hotelCurrency === "USD"
          ? moment(new Date(new Date().getTime() - 86400000))
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat)
          : moment(new Date())
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat);

      if (temp?.roomTypes?.includes("blockedRooms")) {
        temp.blockedRooms = true;
      } else if (temp?.roomTypes?.includes("availableRooms")) {
        temp.availableRooms = true;
      } else if (temp?.roomTypes?.includes("dirtyRooms")) {
        temp.dirtyRooms = true;
      } else if (temp?.roomTypes?.includes("cleanRooms")) {
        temp.cleanRooms = true;
      } else if (temp?.roomTypes?.includes("bookedRooms")) {
        temp.bookedRooms = true;
      }

      if (temp?.roomTypes !== null && temp?.roomTypes !== undefined) {
        temp.roomTypes =
          temp?.roomTypes.length > 0 &&
          temp?.roomTypes.filter(
            (item) =>
              item !== "blockedRooms" &&
              item !== "availableRooms" &&
              item !== "dirtyRooms" &&
              item !== "cleanRooms" &&
              item !== "bookedRooms"
          );
      } else {
        temp.roomTypes = null;
      }

      // const data = await getBookingData(temp);
      //   const resp = new BookingDataModel(
      //     data,
      //     "resourceTimelineWeek",
      //     "THREE_SLOT"
      //   );
      //   setRoomNumbers(resp.roomsData);
      //   setOriginalAllRoomInfo(data.allRoomReservations.singleRoomReservations);

      //   setInitialData(resp);

      const roomsAray = [];
      const InitialArray = [];
      let refArray = [];
      let calendarApiAray = [];
      const grouping = groupData?.map((item) => {
        let res = new NewBookingDataModel(
          item,
          "resourceTimelineWeek",
          hotel.slotType === "NOON" ? "THREE_SLOT" : hotel.slotType
        );

        roomsAray.push(res.roomsData);
        InitialArray.push(res);

        refArray.push(calendarRef);

        // calendarApiAray.push(calendarRef?.current?.getApi());
      });
      setinitailDataArray(InitialArray);
      setroomNumberArray(roomsAray);

      // setCalendarRefArray(refArray);

      setLoading(false);
    } catch (er) {
      console.log(er);
    }
  };

  // useEffect(() => {
  //   let apiArray = [];
  //   if (calendarRefArray?.length > 0) {
  //   }
  //   calendarRefArray.map((refval) => {
  //     if (refval !== null && refval !== undefined) {
  //       apiArray.push(refval?.current?.getApi());
  //     }
  //   });

  // setcalendarApiArray(apiArray);
  // }, [calendarRefArray]);

  const getParamsForView = (val, arr) => {
    if (arr.length > 0) {
      let title = arr[0].view.type;
      let currentDate = arr[0].getDate();

      let tempParams = Object.assign(navigationParams);
      if (views[title] === 30) {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      } else {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      }
      setToday(currentDate);
      tempParams.viewType = title;
      tempParams.numOfDays = views[title];
      tempParams.availableRooms = selectedRoom?.includes("availableRooms");
      tempParams.blockedRooms = selectedRoom?.includes("blockedRooms");
      tempParams.dirtyRooms = selectedRoom?.includes("dirtyRooms");
      tempParams.cleanRooms = selectedRoom?.includes("cleanRooms");
      tempParams.bookedRooms = selectedRoom?.includes("bookedRooms");
      tempParams.startDate = moment(currentDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(dateFormat);
      const temporary = [];
      if (
        selectedRoom.some((e) => e.id === "All") ||
        selectedRoom.length === 0
      ) {
        tempParams.roomTypes = rooms.forEach((e) => {
          if (e.id !== "All" && e.id !== "Unselect") temporary.push(e.id);
        });
      } else {
        selectedRoom.forEach((room) => {
          temporary.push(room.id);
        });
        tempParams.roomTypes = temporary;
      }
      tempParams.clusterRooms = false;
      return tempParams;
    } else {
      let title = calendarApi.view.type;
      let currentDate = calendarApi.getDate();

      let tempParams = Object.assign(navigationParams);
      if (views[title] === 30) {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      } else {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      }

      tempParams.viewType = title;
      tempParams.numOfDays = views[title];
      tempParams.availableRooms = selectedRoom?.includes("availableRooms");
      tempParams.blockedRooms = selectedRoom?.includes("blockedRooms");
      tempParams.dirtyRooms = selectedRoom?.includes("dirtyRooms");
      tempParams.cleanRooms = selectedRoom?.includes("cleanRooms");
      tempParams.bookedRooms = selectedRoom?.includes("bookedRooms");
      tempParams.startDate = moment(currentDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(dateFormat);
      const temporary = [];
      if (
        selectedRoom.some((e) => e.id === "All") ||
        selectedRoom.length === 0
      ) {
        tempParams.roomTypes = rooms.forEach((e) => {
          if (e.id !== "All" && e.id !== "Unselect") temporary.push(e.id);
        });
      } else {
        selectedRoom.forEach((room) => {
          temporary.push(room.id);
        });
        tempParams.roomTypes = temporary;
      }
      tempParams.clusterRooms = false;
      return tempParams;
    }
  };

  const getRoomsData = async () => {
    const resp = await getAllRoomTypess();
    const temp_rooms = [];

    for (const [key, value] of Object.entries(resp)) {
      rooms.push({ text: value, id: key });
    }
    setRooms(rooms);
    setSelectedRoom("All");
  };
  // To detect the changes in the calendar and re-rendering
  useEffect(() => {
    if (calendarApi === null && groupData?.length) {
      //   getRoomsData();
      fetchBookingData();

      setCalendarApi(calendarRef?.current?.getApi());
    }
  }, [calendarApi, groupData, calendarRef]);

  const getSource = (source) => {
    if (source !== undefined) {
      if (channelLogoMap[source] === undefined) return "";
      return channelLogoMap[source];
    }
  };

  useEffect(() => {
    if (
      calendarApiArray[0] !== null &&
      calendarApiArray[0] !== undefined &&
      calendarApiArray[0] !== ""
    ) {
      if (
        sessionStorage.getItem("types") !== null &&
        sessionStorage.getItem("types") !== undefined &&
        sessionStorage.getItem("types") !== "" &&
        calendarApiArray !== null &&
        calendarApiArray !== undefined &&
        calendarApiArray?.length > 0
      ) {
        if (sessionStorage.getItem("types") === "day") {
          const arr = [];
          const res = calendarApiArray.map((itemss) => {
            itemss.changeView("resourceTimelineDay");
            arr.push(itemss);
          });
          setclaendarViewType("resourceTimelineDay");
          setcalendarApiArray(arr);
          let tempData = getParamsForView("day", calendarApiArray);

          fetchBookingDataWithParams(tempData, "day");
        } else if (sessionStorage.getItem("types") === "week") {
          const arr = [];
          const res = calendarApiArray.map((itemss) => {
            itemss.changeView("resourceTimelineWeek");

            arr.push(itemss);
          });
          setclaendarViewType("resourceTimelineWeek");
          setcalendarApiArray(arr);

          let tempData = getParamsForView("week", arr);
          fetchBookingDataWithParams(tempData, "week");
        } else if (sessionStorage.getItem("types") === "month") {
          const arr = [];
          const res = calendarApiArray.map((itemss) => {
            itemss.changeView("resourceTimelineMonthCustom");
            arr.push(itemss);
          });
          setcalendarApiArray(arr);
          setclaendarViewType("resourceTimelineMonthCustom");

          let tempData = getParamsForView("month", arr);
          fetchBookingDataWithParams(tempData, "month");
        }
      } else {
        sessionStorage.setItem("types", "week");
      }
    }
  }, [sessionStorage.getItem("types")]);

  // const [checked, setChecked] = useState(false);

  const handleChangeName = (event) => {
    // setChecked(event.target.checked);
    if (event.target.checked) {
      let tempData = getParamsForView(
        sessionStorage.getItem("types"),
        calendarApiArray
      );
      tempData.clusterRooms = true;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    } else {
      let tempData = getParamsForView(
        sessionStorage.getItem("types"),
        calendarApiArray
      );
      tempData.clusterRooms = false;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    }

    setshowRoomName(event.target.checked);
    sessionStorage.setItem("showRoomName", event.target.checked);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("showRoomName") === undefined ||
      sessionStorage.getItem("showRoomName") === null ||
      sessionStorage.getItem("showRoomName") === ""
    ) {
      setshowRoomName(false);
    } else {
      const data =
        sessionStorage.getItem("showRoomName") === "true" ? true : false;

      setshowRoomName(data);
    }
  }, [sessionStorage.getItem("showRoomName")]);

  useEffect(() => {
    if (groupData?.length > 0)
      calRefArray.current = Array.from({ length: groupData?.length }, () =>
        React.createRef()
      );
  }, [groupData]);

  useEffect(() => {
    let tempArray = [];
    if (calRefArray.current?.length > 0) {
      calRefArray.current.map((calRef) => {
        tempArray.push(calRef.getApi());
      });
    }
    setcalendarApiArray(tempArray);
    if (tempArray.length > 0) {
      let tempData = getParamsForView("week", tempArray);
      fetchBookingDataWithParams(tempData, "week");
    }
  }, [calRefArray.current]);

  const getOccupancyColor = (occupancy) => {
    if (occupancy === "Sold out") return "#f87171";
    const rate = parseInt(occupancy);
    if (rate >= 80) return "#fbbf24";
    if (rate >= 60) return "#fde68a";
    return "#a5f3fc";
  };

  const [dateRange, setdateRange] = useState([]);

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (groupData.length > 0) {
      setExpanded(groupData.map((item) => item.roomTypeId));
    }
  }, [groupData]);

  const handleChange = (id) => {
    setExpanded(
      (prevExpanded) =>
        prevExpanded.includes(id)
          ? prevExpanded.filter((itemId) => itemId !== id) // Collapse if already expanded
          : [...prevExpanded, id] // Expand otherwise
    );
  };

  const [selectedHotels, setSelectedHotels] = useState([]);
  const [roomTypeIds, setroomTypeIds] = useState([]);

  const isAllSelected = selectedHotels.length === groupData.length;
  const [roomTypeNames, setroomTypeNames] = useState(
    groupData?.map((item) => item.roomTypeName)
  );

  useEffect(() => {
    setroomTypeNames(groupData?.map((item) => item.roomTypeName));
    setroomTypeIds(groupData?.map((item) => item.roomTypeId));
    setSelectedHotels(groupData?.map((item) => item.roomTypeId));
  }, [groupData]);

  const handleChanges = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      // Select or deselect all based on current state
      setSelectedHotels(
        isAllSelected
          ? [groupData.map((item) => item.roomTypeId)[0]]
          : groupData.map((item) => item.roomTypeId)
      );
    } else {
      if (value.length > 0) {
        setSelectedHotels(value);
      }
    }
  };

  const renderValue = (selected) => {
    if (selected.length === groupData.length) {
      return "All selected";
    }
    return selected
      .map((roomTypeId) => roomTypeNames[roomTypeIds.indexOf(roomTypeId)])
      .join(", ");
  };

  // function getRateAndOccupancy(inputDate, data) {
  //   const parsedDate = moment(inputDate, "ddd MMM DD YYYY").format(
  //     "YYYY-MM-DD"
  //   );

  //   const result = data.find((item) => item.date === parsedDate);

  //   if (result) {
  //     return { rate: result.rate, occupancy: result.occupancy };
  //   } else {
  //     return "Date not found";
  //   }
  // }

  function getRateAndOccupancy(inputDate, roomId) {
    const roomData = roomInvData.get(roomId);
    const parsedDate = moment(inputDate, "ddd MMM DD YYYY").format(
      "YYYY-MM-DD"
    );

    if (!roomData) {
      return { rate: "", occupancy: "" };
    }

    // Step 2: Find the rate and occupancy for the given date
    const result = roomData.find((item) => item.date === parsedDate);

    if (result) {
      return { rate: result.rate, occupancy: result.occupancy };
    } else {
      return { rate: "", occupancy: "" };
    }

    // Step 1: Get the data for the specific roomId from the Map
  }

  return groupData.length > 0 &&
    roomNumberArray?.length > 0 &&
    initailDataArray?.length > 0 ? (
    <>
      <div>
        {show && actionType === "add" && showModal.resource ? (
          <ModalView
            viewType={calendarApiArray[0].view.type}
            reload={fetchBookingDataWithoutLoad}
            reloadagain={reloadData}
            calendarApi={calendarApi}
            eventType={actionType}
            eventData={showModal}
            open={show}
            handleAddEventSidebar={handleAddEventSidebar}
            addEvent={setNewData}
            roomInformation={
              roomInfo.roomTypeInformation[
                showModal?.resource?.extendedProps?.roomTypeId.split(",")[0]
              ]
            }
            openTheBookingModal={openTheBookingModal}
            selectedLanguage={selectedLanguage}
            isGroup={true}
          ></ModalView>
        ) : null}

        {openBookingModal && (
          <BookingModal
            open={openBookingModal}
            close={closeTheBookingModal}
            bookingData={bookingData}
            invoiceData={invoiceData}
            customFolioConfig={customFolioConfig}
            currency={currency}
            allCompanyDetails={allCompanyDetails}
            summaryData={summaryData}
            hotelType={hotelType}
            hotelLogo={hotelLogo}
            defaultCtadropdown={defaultCtadropdown}
            reservationData={reservationData}
            reload={fetchBookingDataWithoutLoad}
            selectedLanguage={selectedLanguage}
          />
        )}
        {show && actionType === "DragorDrop" ? (
          <DragModalView
            viewType={calendarApiArray[0].view.type}
            reload={fetchBookingDataWithoutLoad}
            calendarApi={calendarApiArray[0]}
            eventType={actionType}
            eventData={showModal}
            open={show}
            handleAddEventSidebar={modify}
            addEvent={changeDateTime}
            moveEvent={moveToNewRoom}
            roomInformation={groupData}
            roomTypeInfo={roomInfo}
            edit={"edit"}
            selectedLanguage={selectedLanguage}
          ></DragModalView>
        ) : null}
        {show && actionType === "extend" ? (
          <ExtendModalView
            viewType={calendarApiArray[0].view.type}
            eventData={showModal}
            open={show}
            handleAddEventSidebar={modify}
            addEvent={changeDateTime}
            reload={fetchBookingDataWithoutLoad}
            selectedLanguage={selectedLanguage}
          ></ExtendModalView>
        ) : null}
        {show && actionType === "edit" ? (
          <EditModalView
            viewType={calendarApiArray[0].view.type}
            reload={fetchBookingDataWithoutLoad}
            lockEvent={lockEvent}
            removeEvent={removeEvent}
            calendarApi={calendarApi}
            eventType={actionType}
            eventData={showModal}
            open={show}
            handleAddEventSidebar={handleAddEventSidebar}
            addEvent={handleAddEventSidebar}
            roomInformation={originalAllRoomInfo}
            roomTypeInfo={roomInfo.roomTypeInformation}
            setShowModal={setShowModal}
            setActionType={setActionType}
            setShow={setShow}
            selectedLanguage={selectedLanguage}
          ></EditModalView>
        ) : null}

        {show &&
          actionType === "edit-blocked" &&
          (hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
            hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
            JSON.parse(hotel?.accessControl?.user_feature_map?.HideUnBlockRooms)
              .read !== "YES") && (
            <UnblockView
              reload={fetchBookingDataWithoutLoad}
              type="unblock"
              eventData={showModal}
              open={show}
              closeFunc={handleAddEventSidebar}
              roomInformation={originalAllRoomInfo}
              roomTypeInfo={roomInfo.roomTypeInformation}
              selectedLanguage={selectedLanguage}
            ></UnblockView>
          )}
      </div>

      <Drawer
        anchor={"right"}
        open={multiOption === "group"}
        onClose={() => {
          setMultiOption("");
        }}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
      >
        <MultipleBookingView
          calendarApi={calendarApiArray[0]}
          hotelId={hotelId}
          reload={fetchBookingDataWithoutLoad}
          onClose={() => {
            setMultiOption("");
          }}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          viewType={claendarViewType}
          transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
          selectedLanguage={selectedLanguage}
        ></MultipleBookingView>
      </Drawer>

      <Drawer
        anchor={"right"}
        open={multiOption === "enquiry"}
        onClose={() => {
          setMultiOption("");
        }}
        className={classes.drawers}
        classes={{
          paper: classes.drawerPapers,
        }}
        transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
      >
        <NewEnquiry
          calendarApi={calendarApiArray[0]}
          hotelId={hotelId}
          reload={fetchBookingDataWithoutLoad}
          onClose={() => {
            setMultiOption("");
          }}
          className={classes.drawers}
          classes={{
            paper: classes.drawerPapers,
          }}
          addEvent={setNewData}
          viewType={claendarViewType}
          transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
          selectedLanguage={selectedLanguage}
        ></NewEnquiry>
      </Drawer>

      <Drawer
        anchor={"right"}
        open={multiOption === "walkin"}
        onClose={() => {
          setMultiOption("");
        }}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
      >
        <Walkin
          calendarApi={calendarApiArray[0]}
          hotelId={hotelId}
          reload={fetchBookingDataWithoutLoad}
          onClose={() => {
            setMultiOption("");
          }}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          addEvent={setNewData}
          viewType={claendarViewType}
          transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
          openTheBookingModal={openTheBookingModal}
          selectedLanguage={selectedLanguage}
        ></Walkin>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={multiOption === "block"}
        onClose={() => {
          setMultiOption("");
        }}
        className={classes.drawers}
        classes={{
          paper: classes.drawerPapers,
        }}
        transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
      >
        <BlockRoom
          calendarApi={calendarApiArray[0]}
          hotelId={hotelId}
          reload={fetchBookingDataWithoutLoad}
          onClose={() => {
            setMultiOption("");
          }}
          className={classes.drawers}
          classes={{
            paper: classes.drawerPapers,
          }}
          addEvent={setNewData}
          viewType={claendarViewType}
          transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
          selectedLanguage={selectedLanguage}
        ></BlockRoom>
      </Drawer>

      <Drawer
        anchor={"right"}
        open={showUnassigned}
        onClose={() => {
          setShowUnassigned(false);
        }}
        className={classes.drawers}
        classes={{
          paper: classes.drawerPapers,
        }}
        transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
      >
        <UnassignedView
          data={initialData}
          date={unassignedDate}
          closeFunc={() => {
            setShowUnassigned(false);
          }}
          reload={fetchBookingDataWithoutLoad}
          selectedLanguage={selectedLanguage}
        ></UnassignedView>
      </Drawer>

      <>
        <Grid item container xs={12} spacing={2}>
          <Grid
            item
            xs={2}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            alignSelf="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                color="primary"
                sx={{ overflow: "auto" }}
                style={{ border: "none" }}
                onClick={() => {
                  let tempData = getParamsForView("subtract", calendarApiArray);
                  fetchBookingDataWithParams(tempData);
                  const arr = [];
                  const res = calendarApiArray.map((itemss, index) => {
                    itemss.prev();
                  });
                  setShowUnassigned(false);
                }}
              >
                <ArrowBackIosNew />
              </IconButton>

              {/* <Switch checked={showRoomName} onChange={handleChangeName} /> */}
            </Grid>
          </Grid>

          <Grid item container xs={8} sm={8} md={7} lg={7} xl={7} spacing={2}>
            {
              <Grid
                container
                justifyContent="flex-start"
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
              >
                <FormControl fullWidth>
                  <InputLabel id="multi-select-label">Select Rooms</InputLabel>
                  <Select
                    labelId="multi-select-label"
                    multiple
                    value={selectedHotels}
                    onChange={handleChanges}
                    renderValue={renderValue}
                    className="mr-4"
                    label={"Select Rooms"}
                    size="small"
                  >
                    <MenuItem value="all">
                      <Checkbox checked={isAllSelected} />
                      <ListItemText primary="Select Rooms" />
                    </MenuItem>

                    {groupData?.map((item) => (
                      <MenuItem key={item.roomTypeId} value={item.roomTypeId}>
                        <Checkbox
                          checked={selectedHotels.includes(item.roomTypeId)}
                        />
                        <ListItemText primary={item.roomTypeName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              container
              justifyContent="center"
              alignSelf="center"
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={today}
                  onChange={(newValue) => {
                    setToday(newValue);
                    const arr = [];
                    const res = calendarApiArray.map((itemss) => {
                      itemss.gotoDate(newValue);
                      arr.push(itemss);
                    });

                    setcalendarApiArray(arr);
                    let tempData = getParamsForView("date", arr);
                    fetchBookingDataWithParams(tempData);
                    setShowUnassigned(false);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div ref={inputRef} {...inputProps}>
                        {/* Render the calendar icon with increased size */}
                        {InputProps?.endAdornment && (
                          <Box sx={{ fontSize: 32 }}>
                            {InputProps.endAdornment}
                          </Box>
                        )}
                      </div>
                    </Box>
                  )}
                />
              </LocalizationProvider>

              <ButtonGroup
                sx={{
                  overflow: "auto",
                  paddingLeft: 2,
                  paddingRight: 3,
                }}
                orientation={matches ? "horizontal" : "vertical"}
                size={matches ? "medium" : "small"}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    backgroundColor: `${
                      dashboardView === "fullView" ? "#36454F" : "white"
                    }`,
                    color: `${
                      dashboardView === "fullView" ? "white" : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  variant={
                    dashboardView === "fullView" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("fullView", true);
                    setDashboardView("fullView");
                  }}
                >
                  {`${t("Full view")}`}
                </Button>

                <Button
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    backgroundColor: `${
                      dashboardView === "roomView" ? "#36454F" : "white"
                    }`,
                    color: `${
                      dashboardView === "roomView" ? "white" : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  variant={
                    dashboardView === "roomView" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("fullView", false);
                    setDashboardView("roomView");
                  }}
                >
                  {`${t("Room view")}`}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            item
            xs={2}
            sm={2}
            md={2}
            lg={4}
            xl={4}
            alignSelf="center"
            justifyContent="flex-end"
            container
          >
            <Grid item container justifyContent="flex-end" xs={12}>
              {unassingedCount > 0 && (
                <Tooltip
                  arrow
                  title={`${unassingedCount} ${t(
                    "Unassigned and Hold reservations"
                  )} `}
                >
                  {unassingedCount > 0 && (
                    <span className="menu-text">
                      <Badge
                        badgeContent={unassingedCount}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "#F64E60",
                            color: "white",
                          },
                        }}
                      >
                        <Button
                          style={{
                            borderRadius: "50px",
                            backgroundColor: "#36454F",
                            color: "white",
                          }}
                          variant="contained"
                          onClick={() => {
                            setUnassignedDate(getStartEndDates());
                            setShowUnassigned(true);
                          }}
                        >
                          UA
                        </Button>
                      </Badge>
                    </span>
                  )}
                </Tooltip>
              )}
              <FormControl
                fullWidth
                size="small"
                style={{ width: "6rem", marginLeft: "1rem" }}
              >
                <InputLabel
                  style={{ width: "6rem" }}
                  id="demo-simple-select-label"
                >
                  {t("Create")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    setMultiOption(e.target.value);
                    setMenuOpen(false);
                  }}
                  style={{ marginLeft: "0rem", width: "6rem" }}
                  className="mr-4"
                  label={t("Create")}
                  size="small"
                  value={multiOption}
                >
                  <MenuItem value={"walkin"}> {t("Walk-in")}</MenuItem>
                  <MenuItem value={"enquiry"}>{t("Enquiry")}</MenuItem>
                  {(hotel?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                    hotel?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
                    (hotelemail !== "reservation@maharashtratourism.gov.in" &&
                      JSON.parse(
                        hotel?.accessControl?.user_feature_map?.HideBlockRooms
                      ).read !== "YES")) && (
                    <MenuItem value={"block"}>{t("Block Room")}</MenuItem>
                  )}
                  <MenuItem value={"group"}>{t("Group booking")}</MenuItem>
                </Select>
              </FormControl>
              <ButtonGroup
                sx={{
                  overflow: "auto",
                  paddingLeft: 2,
                  paddingRight: 3,
                }}
                orientation={matches ? "horizontal" : "vertical"}
                size={"small"}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  size="small"
                  style={{
                    backgroundColor: `${
                      checkForEmptyCalendar() &&
                      calendarApiArray[0].view.type === "resourceTimelineDay"
                        ? "#36454F"
                        : "white"
                    }`,
                    color: `${
                      checkForEmptyCalendar() &&
                      calendarApiArray[0].view.type === "resourceTimelineDay"
                        ? "white"
                        : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  variant={
                    checkForEmptyCalendar() &&
                    calendarApiArray[0].view.type === "resourceTimelineDay"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("types", "day");
                    const arr = [];
                    const res = calendarApiArray.map((itemss) => {
                      itemss.changeView("resourceTimelineDay");
                      arr.push(itemss);
                    });
                    setclaendarViewType("resourceTimelineDay");
                    setcalendarApiArray(arr);
                    let tempData = getParamsForView("day", arr);
                    fetchBookingDataWithParams(tempData, "day");
                  }}
                >
                  Day
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  style={{
                    backgroundColor: `${
                      checkForEmptyCalendar() &&
                      calendarApiArray[0].view.type === "resourceTimelineWeek"
                        ? "#36454F"
                        : "white"
                    }`,
                    color: `${
                      checkForEmptyCalendar() &&
                      calendarApiArray[0].view.type === "resourceTimelineWeek"
                        ? "white"
                        : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  size="small"
                  variant={
                    checkForEmptyCalendar() &&
                    calendarApiArray[0].view.type === "resourceTimelineWeek"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("types", "week");
                    const arr = [];
                    const res = calendarApiArray.map((itemss) => {
                      itemss.changeView("resourceTimelineWeek");
                      arr.push(itemss);
                    });
                    setclaendarViewType("resourceTimelineWeek");

                    setcalendarApiArray(arr);
                    let tempData = getParamsForView("week", arr);
                    fetchBookingDataWithParams(tempData, "week");
                  }}
                >
                  Week
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  style={{
                    backgroundColor: `${
                      checkForEmptyCalendar() &&
                      calendarApiArray[0].view.type ===
                        "resourceTimelineMonthCustom"
                        ? "#36454F"
                        : "white"
                    }`,
                    color: `${
                      checkForEmptyCalendar() &&
                      calendarApiArray[0].view.type ===
                        "resourceTimelineMonthCustom"
                        ? "white"
                        : "#36454F"
                    }`,
                    borderColor: "#36454F",
                  }}
                  size="small"
                  variant={
                    checkForEmptyCalendar() &&
                    calendarApiArray[0].view.type ===
                      "resourceTimelineMonthCustom"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    sessionStorage.setItem("types", "month");
                    const arr = [];
                    const res = calendarApiArray.map((itemss) => {
                      itemss.changeView("resourceTimelineMonthCustom");
                      arr.push(itemss);
                    });
                    setclaendarViewType("resourceTimelineMonthCustom");
                    setcalendarApiArray(arr);

                    let tempData = getParamsForView("month", arr);
                    fetchBookingDataWithParams(tempData, "month");
                  }}
                >
                  Month
                </Button>
              </ButtonGroup>

              <IconButton
                color="primary"
                onClick={() => {
                  let tempData = getParamsForView("add", calendarApiArray);
                  fetchBookingDataWithParams(tempData);
                  const arr = [];
                  const res = calendarApiArray.map((itemss, index) => {
                    itemss.next();
                  });
                  setShowUnassigned(false);
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {claendarViewType !== "resourceTimelineMonthCustom" && (
          <Accordion
            style={{
              width: "100%",
              marginBottom: "1rem",
              borderRadius: "10px",
              border: "1px solid #36454F",
              marginTop: "1rem",
            }}
            key={1}
            expanded={false}
          >
            <AccordionSummary
              aria-controls={`panel${1}bh-content`}
              id={`panel${1}bh-header`}
              sx={{
                paddingTop: "5px",
                paddingBottom: "0px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ p: 0 }}
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* Properties Label */}
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    flexShrink: 0,
                    whiteSpace: "nowrap",
                    maxWidth: "7rem",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#36454F",
                    }}
                  >
                    {"Room types"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm="auto">
                  <div
                    style={{
                      width: "2px",
                      height: "40px",
                      backgroundColor: "#CCCCCC",
                      margin: "0 10px",
                    }}
                  ></div>
                </Grid>

                {/* Date Range Mapping */}
                {invtenoryData
                  .slice(
                    0,
                    claendarViewType === "resourceTimelineWeek"
                      ? 7
                      : invtenoryData.length
                  )
                  .map((date, idx) => (
                    <React.Fragment key={idx}>
                      <Grid
                        item
                        xs={true} // Let Grid handle equal spacing for each date range item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          flexGrow: 1, // Ensure it grows to occupy full space
                          paddingTop: "5px",
                        }}
                      >
                        <div
                          className="w-100 d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#36454F",
                            }}
                          >
                            {moment(date.date).format("DD MMM YY")}
                          </Typography>
                        </div>

                        <div
                          className="w-100 d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          <Box
                            sx={{
                              borderRadius: 1,
                              p: 0.5,
                              width: "100%", // Make the box take full width of the container
                              textAlign: "center",
                              whiteSpace: "nowrap", // Prevent text from wrapping
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                color: "#36454F",
                              }}
                            >
                              {`${date.occupancy}`}
                            </Typography>
                          </Box>
                        </div>
                      </Grid>

                      {claendarViewType !== "resourceTimelineMonthCustom" &&
                        idx !== 6 && (
                          <Grid item xs={12} sm="auto">
                            <div
                              style={{
                                width: "2px",
                                height: "40px",
                                backgroundColor: "#CCCCCC",
                                margin: "0 10px",
                              }}
                            ></div>
                          </Grid>
                        )}
                      {claendarViewType === "resourceTimelineMonthCustom" &&
                        idx !== 14 && (
                          <Grid item xs={12} sm="auto">
                            <div
                              style={{
                                width: "2px",
                                height: "40px",
                                backgroundColor: "#CCCCCC",
                                margin: "0 10px",
                              }}
                            ></div>
                          </Grid>
                        )}
                    </React.Fragment>
                  ))}
              </Grid>
            </AccordionSummary>
          </Accordion>
        )}
      </>

      {groupData
        .filter((val) => selectedHotels?.includes(val.roomTypeId))
        ?.map((item, index) => (
          <Grid
            container
            sx={{ padding: 2, "&:lastChild": 3 }}
            width="100%"
            spacing={2}
          >
            <Grid item display={"inline-flex"} xs={12} height="100%">
              {loading && (
                <Grid
                  item
                  xs={12}
                  position={"fixed"}
                  top="50%"
                  left="50%"
                  container
                >
                  <DimmingDots />
                </Grid>
              )}
              <div>
                <Typography
                  paddingRight={2}
                  variant="h5"
                  style={{ fontWeight: "bold", marginBottom: "1rem" }}
                >
                  {item.roomTypeName}
                </Typography>

                <div style={{ width: "100%" }} className="demo-app-main">
                  <FullCalendar
                    // nowIndicator={true}
                    refetchResourcesOnNavigate={true}
                    eventResizableFromStart={true}
                    resourceAreaWidth={100}
                    schedulerLicenseKey="0974064232-fcs-1639721704"
                    selectOverlap={item.timeType === "PURE_HOURLY"}
                    views={{
                      resourceTimelineWeek: {
                        nowIndicator: true,
                        titleFormat: { month: "short", day: "2-digit" },
                        contentHeight: "auto",
                        height: "auto",
                        selectOverlap: item.timeType === "PURE_HOURLY",
                        slotLabelDidMount: (el) => {
                          if (
                            el.level === 1 ||
                            el.text === "12am" ||
                            el.text === "8am" ||
                            el.text === "4pm"
                          ) {
                            el.el.className = el.el.className + " dont-display";
                          }
                          if (el.level === 0) {
                            el.el.className =
                              el.el.className + " special-display";
                            el.el.childNodes[0].className =
                              el.el.childNodes[0].className + " spe-height";
                          }
                        },
                        slotLabelWillUnmount: (el) => {
                          if (
                            el.level === 1 ||
                            el.text === "12am" ||
                            el.text === "8am" ||
                            el.text === "4pm"
                          ) {
                            el.el.className = el.el.className + " dont-display";
                          }
                          if (el.level === 0) {
                            el.el.className =
                              el.el.className + " special-display";
                            el.el.childNodes[0].className =
                              el.el.childNodes[0].className + " spe-height";
                          }
                        },
                        slotMinTime: "00:00:00",
                        slotMaxTime: "24:00:00",
                        slotDuration:
                          item.timeType === "NOON"
                            ? Noonslot
                            : getSlotType(item.timeType),
                        //   ? "23:00:00" : hourToString[initialData.checkInTimes[initialData.checkInTimes.length - 1]].text,
                        slotLabelContent: (day) => {
                          if (day.level === 0) {
                            return (
                              <span>
                                <Tooltip
                                  arrow
                                  title={`Go to ${day.date.toDateString()}`}
                                >
                                  <Typography
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      const arr = [];
                                      setToday(day.date);

                                      const res = calendarApiArray.map(
                                        (itemss) => {
                                          itemss.changeView(
                                            "resourceTimelineDay"
                                          );
                                          itemss.gotoDate(day.date);
                                          arr.push(itemss);
                                        }
                                      );
                                      setcalendarApiArray(arr);
                                      // calendarApi.changeView("resourceTimelineDay");

                                      // calendarApi.gotoDate(day.date);
                                      let tempData = getParamsForView(
                                        "date",
                                        arr
                                      );
                                      fetchBookingDataWithParams(tempData);
                                    }}
                                    sx={{
                                      color: "#2C3E50 !important",
                                      display: "flex",
                                      paddingRight: "4px",
                                      fontWeight: 600,
                                    }}
                                    component={"div"}
                                    color={"inherit"}
                                  >
                                    <span className="d-flex flex-column">
                                      <span style={{ fontWeight: "400" }}>
                                        {day.date.toDateString()}
                                      </span>

                                      {
                                        <span className="d-flex w-100">
                                          <span>
                                            {`${
                                              getRateAndOccupancy(
                                                day.date.toDateString(),
                                                item.roomTypeId
                                              ).rate
                                            }`}
                                            <span
                                              style={{ marginLeft: "1rem" }}
                                            >
                                              {" "}
                                              {`${
                                                getRateAndOccupancy(
                                                  day.date.toDateString(),
                                                  item.roomTypeId
                                                ).occupancy
                                              }`}
                                            </span>
                                          </span>
                                        </span>
                                      }
                                    </span>
                                  </Typography>
                                </Tooltip>
                              </span>
                            );
                          } else {
                            return (
                              <Typography sx={{ color: "#2C3E50 !important" }}>
                                {day.text}
                              </Typography>
                            );
                          }
                        },
                        eventDidMount: (eve) => {
                          if (
                            eve.event.extendedProps.status === "CONFIRMED" ||
                            eve.event.extendedProps.status === "ADMIN_CONFIRMED"
                          ) {
                            var div = document.createElement("div");
                            div.className =
                              "fc-event-resizer fc-event-resizer-start";
                            var div1 = document.createElement("div");
                            div.className =
                              "fc-event-resizer fc-event-resizer-end";
                            eve.el.appendChild(div);
                            eve.el.appendChild(div1);
                          }
                        },
                        eventContent: (arg) => {
                          const start = moment(
                            arg.event._instance.range.start
                          ).format("DD-MM-YYYY");
                          const end = moment(
                            arg.event._instance.range.end
                          ).format("DD-MM-YYYY");

                          let days = moment(arg.event._instance.range.end).diff(
                            moment(arg.event._instance.range.start),
                            "days"
                          );

                          let len = arg.event.title.length;
                          let finalTital;
                          if (days === 0 && len > 10) {
                            finalTital =
                              arg.event.title.substring(0, 6) + "...";
                          } else if (days === 1 && len > 30) {
                            finalTital =
                              arg.event.title.substring(0, 25) + "...";
                          } else if (days === 2 && len > 45) {
                            finalTital =
                              arg.event.title.substring(0, 40) + "...";
                          } else {
                            finalTital = arg.event.title;
                          }
                          return (
                            <div>
                              <div>
                                <div
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    fontWeight: 600,
                                    fontSize: "1rem",
                                    paddingLeft: "1rem",
                                    color: "#2C3F51",
                                  }}
                                >
                                  {arg.event.extendedProps.locked_status ===
                                    "LOCKED" && (
                                    <Lock
                                      color={
                                        arg.event.extendedProps.status ===
                                          "CHECKED_OUT" ||
                                        arg.event.extendedProps.status ===
                                          "CONFIRMED" ||
                                        arg.event.extendedProps.status ===
                                          "DONE"
                                          ? "primary"
                                          : "inherit"
                                      }
                                      sx={{ marginRight: 1 }}
                                    />
                                  )}

                                  {finalTital}

                                  {
                                    <img
                                      src={getSource(
                                        arg.event.extendedProps.bookingSource
                                      )}
                                      style={{
                                        height: "2rem",
                                        marginLeft: "10px",
                                        borderRadius: "100%",
                                      }}
                                    />
                                  }
                                  {arg.event.extendedProps.balanceDue > 0 && (
                                    <BalanceIcon
                                      style={{
                                        marginLeft: "10px",
                                        color: "darkcyan",
                                      }}
                                    />
                                  )}
                                  {arg.event.extendedProps.bookingNotes ===
                                    "YES" && (
                                    <SpeakerNotesIcon
                                      style={{
                                        marginLeft: "10px",
                                        color: "darkcyan",
                                      }}
                                    />
                                  )}
                                  {arg.event.extendedProps.groupBooking ===
                                    true && (
                                    <GroupsIcon
                                      style={{
                                        marginLeft: "10px",
                                        color: "darkcyan",
                                      }}
                                    />
                                  )}

                                  {arg.event.extendedProps.selfServiceStatus ===
                                    "REQUESTED" && (
                                    <RadioButtonUncheckedIcon
                                      style={{
                                        marginLeft: "10px",
                                        color: "darkcyan",
                                      }}
                                    />
                                  )}

                                  {arg.event.extendedProps.selfServiceStatus ===
                                    "APPROVED" && (
                                    <CheckCircleIcon
                                      style={{
                                        marginLeft: "10px",
                                        color: "darkcyan",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              {/* </LightTooltip> */}
                            </div>
                          );
                        },
                      },
                      resourceTimelineDay: {
                        nowIndicator: true,
                        titleFormat: { month: "short", day: "2-digit" },
                        contentHeight: "auto",
                        height: "auto",
                        selectOverlap: false,
                        slotDuration: "01:00:00",
                        slotLabelContent: (data) => {
                          return (
                            <Typography
                              sx={{
                                color: "#2C3E50 !important",
                                fontWeight: 600,
                              }}
                            >
                              {data.text}
                            </Typography>
                          );
                        },
                        slotLaneContent: (day) => {
                          return (
                            <div>
                              <Typography>{day.time}</Typography>
                            </div>
                          );
                        },
                        eventContent: (arg) => {
                          return (
                            <div>
                              <div
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  fontWeight: 600,
                                  fontSize: "1rem",
                                  paddingLeft: "1rem",
                                  color: "#2C3F51",
                                }}
                              >
                                {arg.event.extendedProps.locked_status ===
                                  "LOCKED" && (
                                  <Lock
                                    color={
                                      arg.event.extendedProps.status ===
                                        "CHECKED_OUT" ||
                                      arg.event.extendedProps.status ===
                                        "CONFIRMED" ||
                                      arg.event.extendedProps.status === "DONE"
                                        ? "primary"
                                        : "inherit"
                                    }
                                    sx={{ marginRight: 1 }}
                                  />
                                )}
                                {arg.event.title}

                                {
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                }
                                {arg.event.extendedProps.balanceDue > 0 && (
                                  <BalanceIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                                {arg.event.extendedProps.bookingNotes ===
                                  "YES" && (
                                  <SpeakerNotesIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                                {arg.event.extendedProps.groupBooking ===
                                  true && (
                                  <GroupsIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}

                                {arg.event.extendedProps.selfServiceStatus ===
                                  "REQUESTED" && (
                                  <RadioButtonUncheckedIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                                {arg.event.extendedProps.selfServiceStatus ===
                                  "APPROVED" && (
                                  <CheckCircleIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            // </LightTooltip>
                          );
                        },
                      },
                      resourceTimelineMonthCustom: {
                        nowIndicator: true,
                        titleFormat: { month: "short", day: "2-digit" },
                        contentHeight: "auto",
                        height: "auto",
                        type: "resourceTimeline",
                        selectOverlap: item.timeType === "PURE_HOURLY",
                        eventMaxStack: 2,
                        dayCount: 30,
                        duration: { days: 30 },
                        slotLabelContent: (day) => {
                          return (
                            <div style={{ width: "100%" }}>
                              {day.level === 0 && (
                                <span style={{ display: "flex" }}>
                                  <Tooltip
                                    title={`Go to ${day.date.toDateString()}`}
                                    arrow
                                  >
                                    <Typography
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        //   calendarApi.changeView("resourceTimelineDay");
                                        setToday(day.date);
                                        //   calendarApi.gotoDate(day.date);
                                        let arr = [];
                                        const res = calendarApiArray.map(
                                          (itemss) => {
                                            itemss.changeView(
                                              "resourceTimelineDay"
                                            );
                                            itemss.gotoDate(day.date);
                                            arr.push(itemss);
                                          }
                                        );
                                        setcalendarApiArray(arr);
                                        let tempData = getParamsForView(
                                          "date",
                                          arr
                                        );
                                        fetchBookingDataWithParams(tempData);
                                      }}
                                      sx={{
                                        color: "#2C3E50 !important",
                                        display: "flex",
                                        paddingRight: "4px",
                                        fontWeight: 600,
                                      }}
                                      component={"div"}
                                    >
                                      {day.text}
                                    </Typography>
                                  </Tooltip>
                                </span>
                              )}
                            </div>
                          );
                        },
                        eventContent: (arg) => {
                          return (
                            <div>
                              <div
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  fontWeight: 600,
                                  fontSize: "1rem",
                                  paddingLeft: "1rem",
                                  color: "#2C3F51",
                                }}
                              >
                                {arg.event.extendedProps.locked_status ===
                                  "LOCKED" && (
                                  <Lock
                                    color={
                                      arg.event.extendedProps.status ===
                                        "CHECKED_OUT" ||
                                      arg.event.extendedProps.status ===
                                        "CONFIRMED" ||
                                      arg.event.extendedProps.status === "DONE"
                                        ? "primary"
                                        : "inherit"
                                    }
                                    sx={{ marginRight: 1 }}
                                  />
                                )}
                                {arg.event.title}

                                {
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                }
                                {arg.event.extendedProps.balanceDue > 0 && (
                                  <BalanceIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                                {arg.event.extendedProps.bookingNotes ===
                                  "YES" && (
                                  <SpeakerNotesIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                                {arg.event.extendedProps.groupBooking ===
                                  true && (
                                  <GroupsIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                                {arg.event.extendedProps.selfServiceStatus ===
                                  "REQUESTED" && (
                                  <RadioButtonUncheckedIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                                {arg.event.extendedProps.selfServiceStatus ===
                                  "APPROVED" && (
                                  <CheckCircleIcon
                                    style={{
                                      marginLeft: "10px",
                                      color: "darkcyan",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            // </LightTooltip>
                          );
                        },
                      },
                    }}
                    droppable={false}
                    contentHeight={"auto"}
                    height="auto"
                    scrollTime={"00:00"}
                    forceEventDuration={true}
                    firstDay={
                      checkForEmptyCalendar()
                        ? calendarApiArray[index].getDate().getDay()
                        : new Date().getDay()
                    }
                    lazyFetching={true}
                    plugins={[interactionPlugin, resourceTimelinePlugin]}
                    headerToolbar={false}
                    resourceLabelContent={(arg) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            color: `${
                              showRoomName
                                ? arg.resource.extendedProps.roomTypeColor ===
                                  "#ffffff"
                                  ? "black"
                                  : arg.resource.extendedProps.roomTypeColor
                                : "black"
                            }`,
                          }}
                          onClick={(e) => {
                            setdirtyRoomId(arg.resource.title);
                            if (
                              arg.resource.extendedProps.dirtyColor ===
                              "bg-danger"
                            ) {
                              handleOpen();
                            }
                          }}
                        >
                          <LightTooltip
                            title={arg.resource.extendedProps.roomType}
                            arrow
                          >
                            <Typography sx={{ fontWeight: "bold" }}>
                              {arg.resource.title}
                            </Typography>
                          </LightTooltip>
                          {arg.resource.extendedProps.dirtyColor ===
                            "bg-danger" && (
                            <FiberManualRecord
                              color="error"
                              fontSize="small"
                              sx={{ ml: 0.5, alignSelf: "center" }}
                            />
                          )}
                          {showRoomName && (
                            <LightTooltip
                              title={arg.resource.extendedProps.roomType}
                              arrow
                            >
                              <Typography sx={{ ml: 0.5, fontWeight: "bold" }}>
                                {` - ${arg.resource.extendedProps.roomTypeCode}`}
                              </Typography>
                            </LightTooltip>
                          )}
                        </div>
                      );
                    }}
                    eventResizeStart={() => {
                      setActionType("extend");
                      sethotelCurrency(item.currency);
                      sethotelCountry(item.country);
                      sethotelSlotType(item.timeType);
                    }}
                    eventDragStart={() => {
                      setActionType("DragorDrop");
                      sethotelCurrency(item.currency);
                      sethotelCountry(item.country);
                      sethotelSlotType(item.timeType);
                    }}
                    initialView="resourceTimelineWeek"
                    initialDate={
                      // hotel.hotelCurrency === "INR"
                      1 === 1
                        ? new Date().toISOString()
                        : new Date(Date.now() - 3600 * 1000 * 24).toISOString()
                    }
                    selectable={true}
                    events={
                      initailDataArray[index].bookingData

                      // initialData.bookingData
                    }
                    eventClick={(event) =>
                      handleDateSelect(
                        event,
                        initailDataArray[index],
                        item.hotelId,
                        item.timeType,
                        item.country,
                        item.currency
                      )
                    }
                    // resources={roomNumbers}
                    resources={roomNumberArray[index]}
                    resourceOrder={function (a, b) {
                      return parseInt(a.initalId) < parseInt(b.initalId)
                        ? -1
                        : a.initalId > b.initalId
                        ? 1
                        : 0;
                    }}
                    stickyHeaderDates={"auto"}
                    eventOverlap={true}
                    select={(event) =>
                      addNew(
                        event,
                        initailDataArray[index],
                        item.hotelId,
                        item.timeType,
                        item.country,
                        item.currency
                      )
                    }
                    // ref={calendarRefArray[index]}
                    ref={(el) => (calRefArray.current[index] = el)}
                    eventChange={handleDragStop}
                    resourceAreaHeaderContent={"Rooms"}
                    eventClassNames={(arg) => {
                      if (arg.event.extendedProps.enquiry === "true") {
                        return ["fc-enquiry-event"];
                      } else {
                        if (arg.event.extendedProps.status === "CONFIRMED") {
                          return ["fc-confirmed-event"];
                        }

                        if (arg.event.extendedProps.status === "CHECKED_OUT") {
                          return ["fc-checkedout-event"];
                        }

                        if (arg.event.extendedProps.status === "DONE") {
                          return ["fc-checkedout-event"];
                        }
                        if (arg.event.extendedProps.status === "BLOCKED")
                          return ["fc-blocked-event"];

                        if (arg.event.extendedProps.status === "COMBO")
                          return ["fc-combo-room"];
                        return ["timeline-calendar"];
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        ))}
    </>
  ) : (
    <MockLoaderTable />
  );
};

// export default GroupTimeline;

function mapStateToProps(state) {
  return { datass: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(GroupTimeline);
