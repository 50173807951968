import axios from "axios";
import { handleSpringDataRequest } from "../../../api";
import { cloneDeep } from "lodash";

const url = new URL(window.location.href);
const hotel_id = url.searchParams.get("hotelId");
const BG_URL = `${process.env.REACT_APP_BG_API_URL}core/api/v1/`;
const SPRING = `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/`;
const BG_API_URL = BG_URL + "reservation/";
const API_URL = SPRING + "reservation/";
const PRICING_URL = SPRING + "pricing/";
const ROOMS_URL = SPRING + "room/";
const USER_URL = `${process.env.REACT_APP_SPRING_API_URL}user/`;
const DJANGO_URL = `${process.env.REACT_APP_API_URL}invoice/`;
const update_customer = "updateCustomerDetails";
const update_customer_res = "updateCustomerResDetails";
const booking_url = "navigationGetRoomBookings";
const booking_url_no_auth = "navigationGetRoomBookingsNoAuth";
const reservation_info_url = "fetchReservation";
const room_type_info_url = "getRoomTypeInformation";
const perform_admin_booking = "performAdminBooking";
const addSegment = "add-segment";
const perform_booking = "performBooking";
const booking_info_url = "fetchBooking";
const prices_url = "getQuickBookPricePayload";
const prices_url_noon = "getQuickBookPricePayloadNoon";
const quick_edit_price_url = "getQuickEditPricePayload";
const checkin_edit_price_url = "getEditCheckinPricePayload";
const checkout_edit_price_url = "getExtensionPricePayload";
const modify_checkin_url = "performEditCheckin";
const modify_checkout_url = "performExtendReservation";
const upgrade_url = "getUpgradePricePayload";
const quick_edit_url = "performAdminModification";
const upgrade_reservation = "moveReservation";
const hold_booking = "hold-booking";
const unassign_reservation = "unassignReservation";
const lock_room = "set-single-reservation-locked-status";
const cancel_booking = "cancel-booking";
const void_charges = "voidCancellationCharges";
const cancel_reservation = "cancel-resIds";
const get_room_bookings = "get-rooms-for-booking";
const get_room_bookings_noon = "get-rooms-for-booking-noon";
const get_unassigned_reservations = "get-all-unassigned-reservations";
const checking_booking = "adminConfirmBooking";
const checkin_reservation = "admin-confirmed-resids";
const checkout_booking = "checkout-booking";
const get_slots_pms = "getCheckinAndCheckoutSlots";
const get_slots_pms_hourly = "getCheckinAndCheckoutSlotsHourly";
const tax_names = "getTaxNamesForRoom";
const combineRoomPrice = "getTotalRoomPrice";
const hotel_tax_names = "getTaxNamesForHotel";
const no_show = "set-booking-noshow";
const non_block = "non-blocked-rooms";
const reassign = "assignRoom";
const price_list = "getPricingList";
const group_final_price = "getFinalRateAndTax";
const release_booking = "releaseBooking";
const edit_booking = "edit-booking";
const swap_reservation = "swapReservation";
const edit_reservation = "edit-reservation";
const checkout_reservation_by_id = "checkout-reservation-by-id";
const update_complimentary = "update-complimentary-info";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};

export const getBookingData = async (params, history) => {
  return await axios
    .post(API_URL + booking_url, params, axiosConfig)
    .then((response) => response.data)
    .then((data) => {
      sessionStorage.setItem("freezed", "NO");
      return data;
    })
    .catch((err) => {
      if (err.response.status === 401 && history) {
        // Handle 401 Unauthorized error
        history.push("/logout");
        localStorage.clear();
      }
      if (err?.response?.data?.message === "Account Suspended!") {
        sessionStorage.setItem("freezed", "YES");
      } else {
        sessionStorage.setItem("freezed", "NO");
      }
    });
};

export const getBookingDataNoauth = async (params, history) => {
  return await axios
    .post(API_URL + booking_url_no_auth, params, axiosConfig)
    .then((response) => response.data)
    .then((data) => {
      sessionStorage.setItem("freezed", "NO");
      return data;
    })
    .catch((err) => {
      if (err.response.status === 401 && history) {
        // Handle 401 Unauthorized error
        history.push("/logout");
        localStorage.clear();
      }
      if (err?.response?.data?.message === "Account Suspended!") {
        sessionStorage.setItem("freezed", "YES");
      } else {
        sessionStorage.setItem("freezed", "NO");
      }
    });
};
export const getReservationInfo = async (params) => {
  return await axios
    .get(
      API_URL + reservation_info_url + `?reservationId=${params}`,
      axiosConfig
    )
    .then((response) => response.data)
    .then((data) => data)
    .catch((err) => {
      console.log(err);
    });
};

export const getReservationInfoWithHotelId = async (params, hotelId) => {
  return await axios
    .get(
      API_URL +
        reservation_info_url +
        `?reservationId=${params}` +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      axiosConfig
    )
    .then((response) => response.data)
    .then((data) => data)
    .catch((err) => {
      console.log(err);
    });
};

export const getBookingInfo = async (params) => {
  return await axios
    .get(API_URL + booking_info_url + `?&bookingId=${params}`, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data)
    .catch((err) => {
      console.log(err);
    });
};

export const getRoomInfo = async (params) => {
  return await axios
    .get(API_URL + room_type_info_url + `?roomTypeId=${params}`, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data)
    .catch((err) => {
      console.log(err);
    });
};

export const perfomAdminBooking = async (params) => {
  return await axios
    .post(BG_API_URL + perform_admin_booking, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const perfomAdminBookingWithHotelId = async (params, hotelId) => {
  return await axios
    .post(
      BG_API_URL +
        perform_admin_booking +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      params
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const addSegmentAndSubSegment = async (bookingId, segment) => {
  return await axios
    .put(API_URL + `add-segment?bookingId=${bookingId}&segment=${segment}`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const addSegmentAndSubSegmentWithHotelId = async (
  bookingId,
  segment,
  hotelId
) => {
  return await axios
    .put(
      API_URL +
        `add-segment?bookingId=${bookingId}&segment=${segment}` +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const perfomBooking = async (params) => {
  return await axios
    .post(BG_API_URL + perform_booking, params)
    .then((resp) => resp.data)
    .then((data) => data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getQuickBookPricePayload = async (params) => {
  return await axios
    .post(API_URL + prices_url, params, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data);
};

export const getQuickBookPricePayloadNoon = async (params) => {
  return await axios
    .post(API_URL + prices_url_noon, params, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data);
};

export const getQuickBookPricePayloadWithHotelId = async (params, hotelId) => {
  return await axios
    .post(
      API_URL + prices_url + "?hotelId=" + hotelId + "&hotel_id=" + hotelId,
      params,
      axiosConfig
    )
    .then((resp) => resp.data)
    .then((data) => data);
};

export const getQuickEditPricePayload = async (params) => {
  return await axios
    .post(API_URL + quick_edit_price_url, params, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data);
};

export const getEditCheckinPricePayload = async (params) => {
  return await axios
    .post(API_URL + checkin_edit_price_url, params, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data);
};

export const getEditCheckinPricePayloadWithHotelId = async (
  params,
  hotelId
) => {
  return await axios
    .post(
      API_URL +
        checkin_edit_price_url +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      params,
      axiosConfig
    )
    .then((resp) => resp.data)
    .then((data) => data);
};

export const getEditCheckoutPricePayload = async (params) => {
  return await axios
    .post(API_URL + checkout_edit_price_url, params, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data);
};
export const getEditCheckoutPricePayloadWithHotelId = async (
  params,
  hotelId
) => {
  return await axios
    .post(
      API_URL +
        checkout_edit_price_url +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      params,
      axiosConfig
    )
    .then((resp) => resp.data)
    .then((data) => data);
};

export const performEditCheckin = async (params) => {
  return await axios
    .post(BG_API_URL + modify_checkin_url, params, axiosConfig)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const performEditCheckinWithHotelId = async (params, hotelId) => {
  return await axios
    .post(
      API_URL +
        modify_checkin_url +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      params,
      axiosConfig
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const performEditCheckout = async (params, bookingId) => {
  try {
    const res = await axios.post(
      BG_API_URL + modify_checkout_url,
      params,
      axiosConfig
    );
    for (let rId of params.resIds) {
      await handleGetDoorLockDetails({
        bookingId: bookingId,
        reservationId: rId,
        actionRequired: "recreate",
      });
    }
    return res.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
  // return await axios
  //   .post(API_URL + modify_checkout_url, params, axiosConfig)
  //   .then((resp) => resp.data)
  //   .catch((err) => {
  //     throw new Error(err.response.data);
  //   });
};
export const performEditCheckoutWithHotelId = async (
  params,
  hotelId,
  bookingId
) => {
  try {
    const res = await axios.post(
      API_URL +
        modify_checkout_url +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      params,
      axiosConfig
    );

    for (let rId of params.resIds) {
      await handleGetDoorLockDetails({
        bookingId: bookingId,
        reservationId: rId,
        actionRequired: "recreate",
      });
    }
    return res.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
  // return await axios
  //   .post(
  //     API_URL +
  //       modify_checkout_url +
  //       "?hotelId=" +
  //       hotelId +
  //       "&hotel_id=" +
  //       hotelId,
  //     params,
  //     axiosConfig
  //   )
  //   .then((resp) => resp.data)
  //   .catch((err) => {
  //     throw new Error(err.response.data);
  //   });
};

export const getUpgradePricePayload = async (params) => {
  return await axios
    .post(API_URL + upgrade_url, params, axiosConfig)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const editBookingFromRes = async (params) => {
  return await axios
    .post(API_URL + quick_edit_url, params, axiosConfig)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const moveReservation = async (params, bookingId) => {
  try {
    const res = await axios.post(
      BG_API_URL + upgrade_reservation,
      params,
      axiosConfig
    );
    await handleGetDoorLockDetails({
      bookingId: bookingId,
      reservationId: params.reservationId,
      actionRequired: "recreate",
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
  // return await axios
  //   .post(API_URL + upgrade_reservation, params, axiosConfig)
  //   .then((resp) => resp.data)
  //   .catch((err) => {
  //     throw new Error(err.response.data);
  //   });
};

export const holdBooking = async (params) => {
  return await axios
    .post(
      BG_API_URL + hold_booking + `?bookingId=${params.bookingId}`,
      params,
      axiosConfig
    )
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const unassignReservation = async (params) => {
  return await axios
    .post(
      BG_API_URL +
        unassign_reservation +
        `?reservationId=${params.reservationId}`,
      params,
      axiosConfig
    )
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const lockReservation = async (params) => {
  return await axios
    .post(
      BG_API_URL +
        lock_room +
        `?status=${params.status}&reservationId=${params.reservationId}`,
      {},
      axiosConfig
    )
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const cancelBooking = async (params) => {
  try {
    const res = await axios.post(
      BG_API_URL + cancel_booking,
      params,
      axiosConfig
    );
    console.log("cancel booking booking id : ", params);
    await handleGetDoorLockDetails({
      bookingId: params.bookingId,
      reservationId: null,
      actionRequired: "destroy",
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
  // return await axios
  //   .post(API_URL + cancel_booking, params, axiosConfig)
  //   .then((resp) => resp.data)
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

export const voidCancellationCharges = async (params) => {
  return await axios
    .post(API_URL + void_charges, params, axiosConfig)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });
};

export const cancelReservation = async (
  params,
  applyCancellationPolicy,
  bookingId
) => {
  try {
    const res = await axios.post(
      BG_API_URL +
        cancel_reservation +
        `\?applyCancellationPolicy=${applyCancellationPolicy}`,
      params,
      axiosConfig
    );
    for (let rId of params.resIds) {
      await handleGetDoorLockDetails({
        bookingId: bookingId,
        reservationId: rId,
        actionRequired: "destroy",
      });
    }
    return res.data;
  } catch (err) {
    console.log(err);
  }
  // return await axios
  //   .post(
  //     API_URL +
  //       cancel_reservation +
  //       `\?applyCancellationPolicy=${applyCancellationPolicy}`,
  //     params,
  //     axiosConfig
  //   )
  //   .then((resp) => resp.data)
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

const handleDestroyXytronCard = async (doorLockDetails, reservationId) => {
  let parseDetails;
  if (reservationId) {
    parseDetails = doorLockDetails.filter(
      (detail) => detail.booking_id === reservationId
    );
  } else {
    parseDetails = doorLockDetails;
  }
  for (let details of parseDetails) {
    try {
      const res = await axios.post("http://127.0.0.1:8081/destroycard.php", {
        booking_id: details["booking_id"],
        callback_url: details["callback_url"],
      });
      // setDestroyCardLogs((prev) => ({ ...prev, [details["booking_id"]]: res }));
    } catch (err) {
      console.log(err);
      // setDestroyCardLogs((prev) => ({ ...prev, [details["booking_id"]]: err }));
    }
  }
};

export const updateCustomer = async (params, id, reservationId) => {
  return await axios
    .put(USER_URL + update_customer, params, {
      params: {
        resId: id,
        reservationId: reservationId,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const updateCustomerRes = async (params, id) => {
  return await axios
    .put(USER_URL + update_customer_res, params, {
      params: {
        resId: id,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const updateCustomerWithHotelId = async (params, id, hotelId) => {
  return await axios
    .put(
      USER_URL +
        update_customer +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      params,
      {
        params: {
          resId: id,
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const updateCustomerResWithHotelId = async (params, id, hotelId) => {
  return await axios
    .put(
      USER_URL +
        update_customer_res +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      params,
      {
        params: {
          resId: id,
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getRoomBookings = async (params) => {
  return await axios
    .post(API_URL + get_room_bookings, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getRoomBookingsNoon = async (params) => {
  return await axios
    .post(API_URL + get_room_bookings_noon, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getUnassignedReservations = async (params) => {
  return await axios
    .get(API_URL + get_unassigned_reservations, {
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const checkingBooking = async (params) => {
  try {
    const res = await axios.get(BG_API_URL + checking_booking, {
      params: {
        bookingId: params,
      },
    });
    await handleGetDoorLockDetails({
      bookingId: params,
      reservationId: null,
      actionRequired: "make",
    });
    return res;
  } catch (err) {
    return err.response;
  }
  // return await axios
  //   .get(API_URL + checking_booking, {
  //     params: {
  //       bookingId: params,
  //     },
  //   })
  //   .then((resp) => resp)
  //   .catch((err) => {
  //     return err.response;
  //   });
};

export const checkingReservation = async (params) => {
  try {
    const res = await axios.post(BG_API_URL + checkin_reservation, params);
    for (let rId of params.resIds) {
      await handleGetDoorLockDetails({
        bookingId: params.bookingId,
        reservationId: rId,
        actionRequired: "make",
      });
    }
    return res.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
  // return
  //   .then((resp) => resp.data)
  //   .catch((err) => {
  //     throw new Error(err.response.data);
  //   });
};

export const handleGetDoorLockDetails = async ({
  bookingId,
  reservationId,
  actionRequired,
}) => {
  // console.log(
  //   "get door lock details : ",
  //   bookingId,
  //   reservationId,
  //   actionRequired
  // );
  const hotelId = bookingId.split("_")[1];
  if (sessionStorage.getItem(`xytronEnabled${hotelId}`) === "true") {
    try {
      const doorLockDetails = await handleSpringDataRequest(
        `core/api/v1/xytron/get-door-lock-details?bookingId=${bookingId}`
      );
      if (actionRequired === "make") {
        await handleMakeXytronCard(doorLockDetails, reservationId);
      } else if (actionRequired === "destroy") {
        await handleDestroyXytronCard(doorLockDetails, reservationId);
      } else if (actionRequired === "recreate") {
        await handleDestroyXytronCard(doorLockDetails, reservationId);
        await handleGetDoorLockDetails({
          bookingId: bookingId,
          reservationId: reservationId,
          actionRequired: "make",
        });
      } else if (actionRequired === "bypass") {
        for (let doorLockDetail of doorLockDetails) {
          const resId = doorLockDetail["booking_id"];
          const resStatus = doorLockDetail["booking_status"];
          if (resStatus === "ADMIN_CONFIRMED") {
            await handleDestroyXytronCard(doorLockDetails, resId);
            await handleGetDoorLockDetails({
              bookingId: bookingId,
              reservationId: resId,
              actionRequired: "make",
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
};

const handleMakeXytronCard = async (doorLockDetails, reservationId) => {
  console.log("checkin reservation id : ", reservationId);
  let parseDetails;
  if (reservationId) {
    parseDetails = doorLockDetails.filter(
      (detail) => detail.booking_id === reservationId
    );
  } else {
    parseDetails = doorLockDetails;
  }
  for (let details of parseDetails) {
    try {
      delete details["booking_status"];
      console.log("details : ", details);
      const res = await axios.post(
        "http://127.0.0.1:8081/makecard.php",
        details
      );
      // setMakeCardLogs((prev) => ({ ...prev, [details["booking_id"]]: res }));
    } catch (err) {
      console.log(err);
      // setMakeCardLogs((prev) => ({ ...prev, [details["booking_id"]]: err }));
    }
  }
};

export const checkoutReservation = async (params) => {
  return await axios
    .post(
      BG_API_URL + checkout_booking,
      {},
      {
        params: {
          bookingId: params,
          releaseInventory: "true",
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getCheckinCheckoutSlots = async (params) => {
  return await axios
    .get(API_URL + get_slots_pms, {
      params: {
        checkin: params.checkin,
        checkout: params.checkout,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getCheckinCheckoutSlotsHourly = async (params) => {
  return await axios
    .get(API_URL + get_slots_pms_hourly, {
      params: {
        checkin: params.checkin,
        checkout: params.checkout,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getCheckinCheckoutSlotsWithHotelId = async (params, hotelId) => {
  return await axios
    .get(
      API_URL + get_slots_pms + "?hotelId=" + hotelId + "&hotel_id=" + hotelId,
      {
        params: {
          checkin: params.checkin,
          checkout: params.checkout,
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getTaxNamesForRoom = async (roomTypeId) => {
  return await axios
    .get(API_URL + tax_names, {
      params: {
        roomTypeId,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getTaxNamesForRoomWithHotelId = async (roomTypeId, hotelId) => {
  return await axios
    .get(API_URL + tax_names + "?hotelId=" + hotelId + "&hotel_id=" + hotelId, {
      params: {
        roomTypeId,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getCombinedPrice = async (data) => {
  return await axios
    .post(PRICING_URL + combineRoomPrice, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getTaxNamesForHotel = async () => {
  return await axios
    .get(API_URL + hotel_tax_names)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const setToNoShow = async (
  bookingId,
  isReservation,
  source,
  applyCancellationPolicy
) => {
  return await axios
    .post(
      BG_API_URL + no_show,
      {},
      {
        params: {
          bookingId: bookingId,
          isReservation: isReservation,
          bookingSrc:
            source?.toLowerCase() === "booking"
              ? source?.toLowerCase()
              : source,
          applyCancellationPolicy: applyCancellationPolicy,
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const setToNoShowBookingDotCom = async (
  bookingId,
  isReservation,
  source,
  fee,
  applyCancellationPolicy
) => {
  return await axios
    .post(
      API_URL + no_show,
      {},
      {
        params: {
          bookingId: bookingId,
          isReservation: isReservation,
          bookingSrc:
            source?.toLowerCase() === "booking"
              ? source?.toLowerCase()
              : source,
          waivedFees: fee,
          applyCancellationPolicy: applyCancellationPolicy,
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getReservationInfoDjango = async (reservationId) => {
  return await axios
    .get(SPRING + "reservation/fetch-res", { params: { resId: reservationId } })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getReservationInfoDjangoWithHotelId = async (
  reservationId,
  hotelId
) => {
  return await axios
    .get(
      SPRING +
        "reservation/fetch-res" +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      { params: { resId: reservationId } }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getGeoCodeDetails = async (zipcodeVal) => {
  return await axios
    .get(SPRING + "reservation/get-geocode-details", {
      params: { zipCode: zipcodeVal },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getGeoCodeDetailsWithHotelId = async (zipcodeVal, hotelId) => {
  return await axios
    .get(
      SPRING +
        "reservation/get-geocode-details" +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      {
        params: { zipCode: zipcodeVal },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getNonBlockedRooms = async (roomTypeId, resId) => {
  return await axios
    .get(ROOMS_URL + non_block, { params: { roomTypeId, resId } })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getNonBlockedRoomsWithStatus = async (roomTypeId, resId) => {
  return await axios
    .get(ROOMS_URL + non_block + "-with-status", {
      params: { roomTypeId, resId },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const assignRoom = async (params) => {
  return await axios
    .post(BG_API_URL + reassign, params, axiosConfig)
    .then((resp) => resp.data)
    .then((data) => data);
};

export const getPriceList = async (data) => {
  return await axios
    .post(PRICING_URL + price_list, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getFinalPrice = async (data, originalAmount, newAmount) => {
  return await axios
    .post(
      PRICING_URL + group_final_price,
      { data },
      {
        params: {
          originalAmount,
          newAmount,
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const releaseBookingId = async (bookingId) => {
  return await axios
    .get(API_URL + release_booking, { params: { bookingId } })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const editBooking = async (data) => {
  return await axios
    .put(BG_API_URL + edit_booking, data)
    .then((resp) => resp.data)
    .catch((err) => {});
};

export const swapReservation = async (data) => {
  return await axios
    .post(BG_API_URL + swap_reservation, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const getBlockedRooms = async (startDate, endDate) => {
  return await axios
    .get(
      SPRING +
        "room/get-blocked-rooms?startDate=" +
        startDate +
        "&endDate=" +
        endDate
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const editReservation = async (data) => {
  return await axios
    .post(API_URL + edit_reservation, data, axiosConfig)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const checkoutReservationById = async (resId, email) => {
  return await axios
    .post(
      API_URL +
        checkout_reservation_by_id +
        "?reservationId=" +
        resId +
        "&emailIds=" +
        email
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const updateComplimentaryBooking = async (params) => {
  return await axios
    .post(API_URL + update_complimentary, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const invalidateNightAudit = async (id) => {
  return await axios
    .get(
      SPRING +
        `nightAudit/invalidate-night-audit-tracker-booking?bookingId=${id}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      console.error(err);
    });
};

export const invalidateNightAuditReservation = async (id) => {
  return await axios
    .get(
      SPRING +
        `nightAudit/invalidate-night-audit-tracker-reservation?resId=${id}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      console.error(err);
    });
};
